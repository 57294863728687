// UserAssignment.js
import React from 'react';
import Select from 'react-select';
import { useCommunities } from '../../context/CommunitiesContext/CommunitiesContext';
import { useUsers } from '../../context/UsersContext/UsersContext'; // Assuming you have a UsersContext

const UserAssignment = ({ onAssignUser }) => {
  const { communities } = useCommunities();
  const { users } = useUsers();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedCommunity, setSelectedCommunity] = React.useState(null);

  const handleCommunitySelect = (selectedOption) => setSelectedCommunity(selectedOption);
  const handleUserSelect = (selectedOption) => setSelectedUser(selectedOption);

  return (
    <div className="assign-container">
      <h3 className="Users-Title">Users</h3>
      <div className="select-input">
        <Select
          placeholder="Select Community"
          options={communities.map((community) => ({ label: community.name, value: community.id }))}
          onChange={handleCommunitySelect}
          value={selectedCommunity} // Just use the selectedCommunity state directly
        />
      </div>
      <div className="select-input">
        <Select
          placeholder="Select User"
          options={users.map((user) => ({ label: user.username, value: user.id }))}
          onChange={handleUserSelect}
          value={selectedUser} // Just use the selectedUser state directly
        />
      </div>
      <button className="assign-button" onClick={() => onAssignUser(selectedCommunity, selectedUser)} disabled={!selectedCommunity || !selectedUser}>
        Assign User
      </button>
    </div>
  );
};

export default UserAssignment;
