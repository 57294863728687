// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Button Styling */
.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

/* Spacing between date input and button */
.date-input {
  margin-right: 10px; /* Adjust as needed */
}
`, "",{"version":3,"sources":["webpack://./src/components/PlantEnergyChart/PlantEnergyChart.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,0CAA0C;AAC1C;EACE,kBAAkB,EAAE,qBAAqB;AAC3C","sourcesContent":["/* Button Styling */\n.button {\n  padding: 10px 20px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  font-size: 16px;\n  cursor: pointer;\n  outline: none;\n  transition: background-color 0.3s ease;\n}\n\n.button:hover {\n  background-color: #0056b3;\n}\n\n/* Spacing between date input and button */\n.date-input {\n  margin-right: 10px; /* Adjust as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
