import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './EnergySystemCard.css';
import { fetchRealtimeData, fetchEnergyFlowData } from '../../services/api';

const EnergySystemCard = ({ system }) => {
  const [realTimeData, setRealTimeData] = useState(null);
  const [energyFlow, setEnergyFlow] = useState(null);

  useEffect(() => {
    // Use the imported fetchRealtimeData function
    const getRealTimeData = async () => {
      try {
        const data = await fetchRealtimeData(system.id);
        setRealTimeData(data || {});
      } catch (error) {
        console.error('Error fetching real-time data:', error);
        // Optionally, handle error by setting an error state
      }
    };

    // Use the imported fetchEnergyFlowData function
    const getEnergyFlowData = async () => {
      try {
        const data = await fetchEnergyFlowData(system.id);
        // console.log(system);

        setEnergyFlow(data || {});
      } catch (error) {
        console.error('Error fetching energy flow data:', error);
        // Optionally, handle error by setting an error state
      }
    };

    getRealTimeData();
    getEnergyFlowData();
  }, [system.id]);

  // Helper function to display power flow direction and value
  const displayPower = (label, power, isPositive) => {
    // Handle cases where power is null or undefined
    if (power == null) {
      return `${label}: N/A`;
    }
    const direction = isPositive ? '' : ' ';
    return `${label}: ${Math.abs(power)}W`;
  };

  // Conditional rendering to handle cases where energyFlow or realTimeData is not yet loaded
  if (!energyFlow || !realTimeData) {
    return <div>Loading...</div>; // Indicate loading status
  }

  return (
    <div className="energy-system-card">
      <img src={system.thumbUrl} alt={system.name} className="system-image" />
      <div className="system-info">
        <h3 className="system-title">
          <Link to={`/Community/${system.communityid}/energy-system/${system.id}`}>{system.name}</Link>
        </h3>
        <p>
          <Link to={`/Community/${system.communityid}`}>{system.community}</Link>
        </p>
        {system.type == '1' ? <span className="tag tag-pro">PVPRO</span> : <span className="tag tag-pv4">PV4</span>}
        <div className="energy-details">
          {/* Example usage; adjust according to the actual data structure */}
          <p>{displayPower('Battery Power', energyFlow.battPower, energyFlow.battPower >= 0)}</p>
          <p>{displayPower('Grid Power', energyFlow.genPower, energyFlow.genPower >= 0)}</p>
          <p>{displayPower('Home Load', energyFlow.loadOrEpsPower, energyFlow.loadOrEpsPower < 0)}</p>
          <p>{displayPower('PV Power', energyFlow.pvPower, energyFlow.pvPower >= 0)}</p>
        </div>
      </div>
      <div className="system-percentage">{energyFlow.soc ?? '-'}%</div>
    </div>
  );
};

export default EnergySystemCard;
