import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import HorseshoeProgressBar from '../../components/HorseshoeProgressBar/HorseshoeProgressBar';

// Additional utility functions or imports can be added here
// For example, `getBatteryColor` if it's not passed as a prop

const EnergySystemInfo = ({ energyFlow, getBatteryColor, realtimeData, generationUseData, options, weather, loadWattage, consumption, calculateBatteryWattage }) => {
  const calculateUsagePercentage = () => {
    if (energyFlow && realtimeData) {
      const currentLoad = energyFlow.loadOrEpsPower || 0;
      const solarIntake = energyFlow.pvPower || 0;
      const currentUsagePercentage = Math.min((currentLoad / solarIntake) * 100, 100).toFixed(2); // Ensure it doesn't go over 100%
      return currentUsagePercentage;
    }
    return 0; // Return 0 if data is not available
  };

  return (
    <div className="Energy-System-Info">
      <div className="Solar-Intake">
        <p className="Solar-Intake-W">Energy Now: {energyFlow ? energyFlow.pvPower : 'Loading...'} W</p> {/* Display wattage */}
        <p className="kwhtoday">Total Generated Today: {realtimeData ? realtimeData.etoday : 'Loading...'} kWh</p>
      </div>
      <div className="Horse-Shoe">
        <HorseshoeProgressBar percentage={calculateUsagePercentage()} loadWattage={loadWattage} consumption={consumption} />
      </div>
      <div className="Battery-Info-es BIE1">
        <p
          className="BatteryTest"
          style={{
            '--battery-percentage': `${energyFlow ? energyFlow.soc : 0}%`,
            '--battery-color': getBatteryColor(energyFlow ? energyFlow.soc : 0),
          }}
        >
          
        </p>
        <p className="BatteryPercentage">{`${calculateBatteryWattage()} kWh ⋅ ${energyFlow ? energyFlow.soc : 'Loading...'} %`}</p>
      </div>
      <div className="Battery-Info-es BIE2" style={{ height: '200px' }}>
        {' '}
        {/* Adjust height as needed */}
        <Doughnut data={generationUseData} options={options} />
      </div>

      <div className="Battery-Info-es BIE3">
        <p className="kwhmonth">kWh Generated Monthly: {realtimeData ? realtimeData.emonth : 'Loading...'} </p>
        <p className="kwhyear">kWh Generated Yearly: {realtimeData ? realtimeData.eyear : 'Loading...'} </p>
        <p className="kwhtotal">kWh Generated Total: {realtimeData ? realtimeData.etotal : 'Loading...'} </p>
      </div>
      <div className="Battery-Info-es BIE4">
        <div className="Energy-System-Weather-ES">
          {weather ? (
            <>
              <img className="weather-icon" src={`https:${weather.condition.icon}`} alt={weather.condition.text} />
              <h1 className="degrees-ES">{`${weather.temp_f}°`}</h1>
            </>
          ) : (
            <span className="Sun-Cloudy-ES"></span> // Placeholder or loading state
          )}
        </div>
      </div>
    </div>
  );
};

export default EnergySystemInfo;
