// Sidebar.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './Sidebar.css'; // Import the corresponding CSS file

function Sidebar() {
  const navigate = useNavigate(); // Initialize the navigate function
  // Define a state to track if the settings menu is hovered
  const [isSettingsHovered, setIsSettingsHovered] = useState(false);

  // Define your sidebar menu items
  const menuItems = [
    { name: '', icon: '👥', url: '/clients' }, // New item for Clients with a placeholder icon
    { name: '', icon: '🏠', url: '/Communities' }, // Replace emoji with actual icons
    { name: '', icon: '🏘️', url: '/EnergySystems' },
    { name: '', icon: '⚙️', url: '#' },
    // ... add more items as needed
  ];

  const handleNavigation = (url) => {
    navigate(url); // Navigate to the given URL
  };

  const handleSubmenuNavigation = (event, url) => {
    event.stopPropagation(); // This stops the click event from bubbling up to the parent li
    navigate(url); // Navigate to the given URL
  };

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        {/* Insert your logo or title here */}
        <h2></h2>
      </div>
      <ul className="sidebar-menu">
        {menuItems.map((item) => (
          <li key={item.icon} className="menu-item" onClick={() => handleNavigation(item.url)} onMouseEnter={() => item.icon === '⚙️' && setIsSettingsHovered(true)} onMouseLeave={() => item.icon === '⚙️' && setIsSettingsHovered(false)}>
            <span className="icon">{item.icon}</span>
            <span className="title">{item.name}</span>

            {/* Submenu for Settings */}
            {item.icon === '⚙️' && isSettingsHovered && (
              <div className="submenu">
                <ul>
                  <li onClick={(event) => handleSubmenuNavigation(event, '/ProfileSettings')}>Profile Settings</li>
                  <li onClick={(event) => handleSubmenuNavigation(event, '/Community/Manage')}>Manage Communities</li>
                  <li onClick={(event) => handleSubmenuNavigation(event, '/User/Manage')}>Manage Users</li>
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
