import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement
import './Communities.css';
// Import your custom components
import Community from '../../components/CommunityCard/CommunityCard';
import EnergySystemCard from '../../components/EnergySystemCard/EnergySystemCard';

function Communities({ baseURL }) {
  const [communities, setCommunities] = useState([]);
  const [energySystems, setEnergySystems] = useState([]); // Added state for energy systems
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  useEffect(() => {
    const token = localStorage.getItem('token'); // Replace with your token retrieval logic
    if (token) {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id; // Or the appropriate field containing the user ID

      axios
        .get(`${baseURL}/api/communities/user/${userId}`)
        .then(async (response) => {
          const updatedCommunities = response.data.map((community) => ({
            ...community,
            url: `Community/${community.id}`, // Append the URL here
          }));
          setCommunities(updatedCommunities);

          const systemsMap = new Map(); // To keep track of unique systems

          for (const community of updatedCommunities) {
            const systemsForCommunity = await fetchEnergySystemsForCommunity(community);
            systemsForCommunity.forEach((system) => {
              if (!systemsMap.has(system.id)) {
                systemsMap.set(system.id, system);
              }
            });
          }

          setEnergySystems(Array.from(systemsMap.values())); // Convert the Map values back to an array
        })
        .catch((error) => {
          console.error('Error fetching communities:', error);
        });
    }
  }, [baseURL]);

  const fetchEnergySystemsForCommunity = async (community) => {
    try {
      const response = await axios.get(`${baseURL}/api/communities/${community.id}/plants`);
      // Append the community name to each energy system
      const energySystemsWithCommunity = response.data.map((system) => ({
        ...system,
        community: community.name,
        communityid: community.id,
      }));
      return energySystemsWithCommunity;
    } catch (error) {
      console.error(`Error fetching energy systems for community ${community.id}:`, error);
      return []; // Return an empty array in case of an error
    }
  };

  // Handler for search term changes
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter energy systems based on search term
  const filteredEnergySystems = energySystems.filter((system) => system.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="Communities">
      <div className="communities-container">
        {/* <div className="communities-header">
          <h1>COMMUNITIES</h1>
          <div className="search-container">
            <span className="search-icon"></span>
            <input className="search-bar" type="search" placeholder="search community"></input>
          </div>
        </div> */}
        <div className="community-section">
          {communities.map((community) => (
            <Community baseURL={baseURL} key={community.name} community={community} />
          ))}
        </div>
      </div>
      <div className="energy-systems-section">
        <h2 className="energy-systems-title">Energy Systems</h2>
        <div className="search-bar-container">
          <input type="text" placeholder="Search energy systems" value={searchTerm} onChange={handleSearchChange} className="search-input" />
        </div>
        {filteredEnergySystems.map((system, index) => (
          <EnergySystemCard baseURL={baseURL} key={index} system={system} />
        ))}
      </div>
    </div>
  );
}

export default Communities;
