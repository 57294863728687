import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchUsers } from '../../services/api'; // Adjust the import path as necessary

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const init = async () => {
      const data = await fetchUsers();
      setUsers(data);
    };
    init();
  }, []);

  return <UsersContext.Provider value={{ users, setUsers }}>{children}</UsersContext.Provider>;
};

export const useUsers = () => useContext(UsersContext);
