import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Clients from './pages/Clients/Clients';
import Communities from './pages/Communities/Communities';
import Community from './pages/Community/Community';
import EnergySystem from './pages/EnergySystem/EnergySystem';
import CommunityManager from './pages/CommunityManager/CommunityManager';
import UserManager from './pages/UserManager/UserManager';
import EnergySystems from './pages/EnergySystems/EnergySystems';
// Make sure the path to the context files are correct
import { CommunitiesProvider } from './context/CommunitiesContext/CommunitiesContext';
import { UsersProvider } from './context/UsersContext/UsersContext';

const baseURL = 'http://dash.clearvision.gg:3001';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') !== null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (isTokenExpired(token)) {
      handleLogout();
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  function isTokenExpired(token) {
    if (!token) return true;
    const payloadBase64 = token.split('.')[1];
    const decodedPayload = JSON.parse(window.atob(payloadBase64));
    const expiration = decodedPayload.exp; // Assumes exp is in seconds
    const currentTime = Date.now() / 1000;
    return expiration < currentTime;
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <CommunitiesProvider>
        <UsersProvider>
          <div className="App">
            {isLoggedIn && <Sidebar />}
            {isLoggedIn && <Navbar />}
            <Routes>
              <Route path="/" element={<Home baseURL={baseURL} onLoginSuccess={handleLoginSuccess} />} />
              <Route path="/clients" element={isLoggedIn ? <Clients baseURL={baseURL} /> : <Navigate to="/" />} />
              <Route path="/communities" element={isLoggedIn ? <Communities baseURL={baseURL} /> : <Navigate to="/" />} />
              <Route path="/community/:communityId" element={isLoggedIn ? <Community baseURL={baseURL} /> : <Navigate to="/" />} />
              <Route path="/community/:communityId/energy-system/:energySystemId" element={isLoggedIn ? <EnergySystem baseURL={baseURL} /> : <Navigate to="/" />} />
              <Route path="/community/manage" element={isLoggedIn ? <CommunityManager baseURL={baseURL} /> : <Navigate to="/" />} />
              <Route path="/user/manage" element={isLoggedIn ? <UserManager baseURL={baseURL} /> : <Navigate to="/" />} />
              <Route path="/energysystems" element={isLoggedIn ? <EnergySystems baseURL={baseURL} /> : <Navigate to="/" />} />
              {/* ... other routes ... */}
            </Routes>
          </div>
        </UsersProvider>
      </CommunitiesProvider>
    </Router>
  );
}

export default App;
