import React from 'react';
import './Navbar.css'; // Import your CSS file for styling

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        {/* Add your logo or brand name here */}
        <h2 className="navbar-title">I-BEC</h2>
        <div className="search-container">
          <span className="report-icon"></span>
          <span className="search-icon"></span>
          <input className="search-bar" type="search" placeholder="search community"></input>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
