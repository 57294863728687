import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CommunityManager.css'; // Import the CSS file
import { useCommunities } from '../../context/CommunitiesContext/CommunitiesContext';
import { useEnergySystems } from '../../hooks/useEnergySystems/useEnergySystems';
import { updateEnergySystem, updateEnergySystemInCommunity, saveCommunity, removePlantFromCommunity, removeUserFromCommunity, fetchCommunities, fetchUsers, fetchEnergySystems, fetchCommunityUsers, fetchCommunityPlants, assignUserToCommunity, assignEnergySystemToCommunity, fetchEnergySystemHistory } from '../../services/api';
import UserAssignment from '../../components/UserAssignment/UserAssignment';
import CommunityList from '../../components/CommunityList/CommunityList';
import EnergySystemAssignment from '../../components/EnergySystemAssignment//EnergySystemAssignment'; // Adjust the path as necessary
import EnergySystemDetails from '../../components/EnergySystemDetails//EnergySystemDetails'; // Adjust the import path as needed
import CommunityForm from '../../components/CommunityForm/CommunityForm';
import EnergySystemHistoryForm from '../../components/EnergySystemHistoryForm/EnergySystemHistoryForm';
import Select from 'react-select';

function CommunityManager({ baseURL }) {
  const { communities } = useCommunities(); // Access global state from context
  const [energySystems] = useEnergySystems();
  const [currentCommunity, setCurrentCommunity] = useState(null);
  const [currentCommunityD, setCurrentCommunityD] = useState(null);
  const [selectedEnergySystem, setSelectedEnergySystem] = useState(null);
  // Initialize all necessary states
  const [communityUsers, setCommunityUsers] = useState({});
  const [communityPlants, setCommunityPlants] = useState({});

  // States for selected items in dropdowns or forms
  const [selectedHistoryEnergySystem, setSelectedHistoryEnergySystem] = useState(null);

  // Function to fetch users for a given community
  function fetchAndSetUsersForAllCommunities() {
    communities.forEach(async (community) => {
      try {
        const response = await fetchCommunityUsers(community.id);
        setCommunityUsers((prevUsers) => ({
          ...prevUsers,
          [community.id]: response,
        }));
      } catch (error) {
        console.error(`Failed to fetch users for community ${community.id}:`, error);
      }
    });
  }

  // Function to fetch users for a given community
  function fetchAndSetPlantsForAllCommunities() {
    communities.forEach(async (community) => {
      try {
        const response = await fetchCommunityPlants(community.id);
        setCommunityPlants((prevPlants) => ({
          ...prevPlants,
          [community.id]: response,
        }));
      } catch (error) {
        console.error(`Failed to fetch Plantst for community ${community.id}:`, error);
      }
    });
  }

  useEffect(() => {
    // Ensure communities data is loaded
    if (communities && communities.length > 0) {
      fetchAndSetUsersForAllCommunities();
      fetchAndSetPlantsForAllCommunities();
    }
  }, [communities]); // Dependency array includes communities to re-run if communities change

  const assignEnergySystem = async () => {
    if (currentCommunityD && selectedEnergySystem) {
      try {
        const postResponse = await assignEnergySystemToCommunity(currentCommunityD.id, selectedEnergySystem.value);
        if (postResponse.status === 201) {
          // Find the plant just added from the cached list
          const assignedPlant = energySystems.find((plant) => plant.id === selectedEnergySystem.value);

          if (assignedPlant) {
            // Update the communityPlants state
            setCommunityPlants((prevState) => ({
              ...prevState,
              [currentCommunityD.id]: [...(prevState[currentCommunityD.id] || []), assignedPlant],
            }));
          }

          // Reset the selected energy system and optionally the selected community
          setSelectedEnergySystem(null);
        }
      } catch (error) {
        console.error('Error assigning energy system to community:', error);
      }
    }
  };

  const onAssignUser = async (community, user) => {
    if (community && user) {
      try {
        // Call the assignUserToCommunity function from the api file
        const response = await assignUserToCommunity(community.value, user.value);
        if (response.status === 201) {
          console.log('User assigned successfully');

          // Fetch the updated list of users for the community
          const updatedUsersResponse = await fetchCommunityUsers(community.value);

          // Update the communityUsers state with the updated list
          setCommunityUsers((prevUsers) => ({
            ...prevUsers,
            [community.value]: updatedUsersResponse, // Ensure this matches the structure of your API response
          }));
        }
      } catch (error) {
        console.error('Error assigning user to community:', error);
      }
    }
  };

  // Function to remove a user and update local state
  const onRemoveUserFromCommunity = async (communityId, userId) => {
    try {
      // Assuming removeUserFromCommunity makes an API call and returns a promise
      await removeUserFromCommunity(communityId, userId);
      setCommunityUsers((prevUsers) => {
        const updatedUsers = { ...prevUsers };
        updatedUsers[communityId] = updatedUsers[communityId].filter((user) => user.id !== userId);
        return updatedUsers;
      });
    } catch (error) {
      console.error('Error removing user from community:', error);
    }
  };

  // Function to remove a plant and update local state
  const onRemovePlantFromCommunity = async (communityId, plantId) => {
    try {
      // Assuming removePlantFromCommunity makes an API call and returns a promise
      await removePlantFromCommunity(communityId, plantId);
      setCommunityPlants((prevPlants) => {
        const updatedPlants = { ...prevPlants };
        updatedPlants[communityId] = updatedPlants[communityId].filter((plant) => plant.id !== plantId);
        return updatedPlants;
      });
    } catch (error) {
      console.error('Error removing plant from community:', error);
    }
  };

  // Function to refresh the history list for an energy system
  const refreshEnergySystemHistory = async (energySystemId) => {
    await fetchEnergySystemHistory(energySystemId);
  };

  // Handler for when an energy system is selected for history
  const handleHistoryEnergySystemSelect = (selectedOption) => {
    setSelectedHistoryEnergySystem(selectedOption);
    if (selectedOption) {
      fetchEnergySystemHistory(selectedOption.value);
    }
  };

  const handleEdit = (community) => {
    setCurrentCommunity(community);
  };

  const handleDelete = async (id) => {
    try {
      // Check if the community has any users or plants associated with it
      const usersInCommunity = communityUsers[id] && communityUsers[id].length > 0;
      const plantsInCommunity = communityPlants[id] && communityPlants[id].length > 0;

      if (usersInCommunity || plantsInCommunity) {
        alert('Cannot delete community because it still has users or energy systems associated with it.');
        return;
      }

      // If no users or plants are associated, proceed with delete
      const response = await axios.delete(`${baseURL}/api/communities/${id}`);
      if (response.status === 200 || response.status === 204) {
        console.log(`Community with id ${id} deleted.`);
        await fetchCommunities();
      } else {
        console.error('Failed to delete community:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error deleting community:', error);
    }
  };

  const handleCommunitySelect = (selectedOption) => {
    // Find the full community object based on the selected option's value (id)
    const selectedCommunity = communities.find((community) => community.id === selectedOption.value);
    setCurrentCommunityD(selectedCommunity);
  };

  return (
    <div className="container">
      {' '}
      <div className="community-manager">
        <CommunityList
          communities={communities}
          handleEdit={handleEdit} // Assume this function sets up a community for editing
          handleDelete={handleDelete} // Assume this function deletes a community
          removeUserFromCommunity={removeUserFromCommunity}
          removePlantFromCommunity={removePlantFromCommunity}
          onRemoveUserFromCommunity={onRemoveUserFromCommunity}
          onRemovePlantFromCommunity={onRemovePlantFromCommunity}
          communityUsers={communityUsers}
          communityPlants={communityPlants}
          baseURL={baseURL}
        ></CommunityList>

        <div className="middle-container">
          <div className="upper-middle">
            <UserAssignment onAssignUser={onAssignUser} />

            <EnergySystemAssignment communities={communities} energySystems={energySystems} currentCommunity={currentCommunityD} selectedEnergySystem={selectedEnergySystem} handleCommunitySelect={handleCommunitySelect} setSelectedEnergySystem={setSelectedEnergySystem} assignEnergySystem={assignEnergySystem} />
          </div>
          <div className="energy-system-history-selector-container">
            <h3>Energy System History</h3>
            <Select className="energy-system-history-select" classNamePrefix="react-select" placeholder="Select Energy System" options={energySystems.map((system) => ({ label: system.name, value: system.id }))} onChange={handleHistoryEnergySystemSelect} value={selectedHistoryEnergySystem} />
            {selectedHistoryEnergySystem && <EnergySystemHistoryForm energySystemId={selectedHistoryEnergySystem.value} baseURL={baseURL} refreshHistory={() => refreshEnergySystemHistory(selectedHistoryEnergySystem.value)} energySystems={energySystems} communities={communities} />}
          </div>
        </div>
        <div className="details-container">
          <EnergySystemDetails energySystems={energySystems} updateEnergySystem={updateEnergySystem} baseURL={baseURL} />
          <CommunityForm currentCommunity={currentCommunity} saveCommunity={saveCommunity} />
        </div>
      </div>
    </div>
  );
}

export default CommunityManager;
