import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import './PlantEnergyPieChart.css'; // Make sure to create and style your Pie Chart accordingly

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class PlantEnergyPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      loading: true,
      error: null,
      selectedDate: new Date(),
      selectedTime: '', // Add selectedTime to state
      chartKey: 0,
      recordIndex: 0, // Initial record index
      timeLabels: [], // Add this to track time labels
    };
    this.chartContainerRef = React.createRef(); // Step 1: Define a ref
  }

  componentDidMount() {
    this.fetchEnergyData();
    // Attach the event listener to the chart container
  }

  componentWillUnmount() {
    // Make sure to remove the event listener when the component unmounts
    if (this.chartContainerRef.current) {
      this.chartContainerRef.current.removeEventListener('wheel', this.handleWheel);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.energySystemId !== prevProps.energySystemId) {
      this.fetchEnergyData();
    }
  }

  fetchEnergyData() {
    const { energySystemId, baseURL, chartTitle } = this.props; // Destructured for cleaner access
    let dateString;
    const selectedDate = new Date(this.state.selectedDate);

    // Helper function to format date in local timezone
    const formatDate = (date, format) => {
      const pad = (num) => (num < 10 ? '0' + num : num);
      switch (format) {
        case 'YYYY-MM-DD':
          return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
        case 'YYYY-MM':
          return `${date.getFullYear()}-${pad(date.getMonth() + 1)}`;
        case 'YYYY':
          return `${date.getFullYear()}`;
        default:
          return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
      }
    };

    switch (chartTitle) {
      case 'Daily':
        dateString = formatDate(selectedDate, 'YYYY-MM-DD');
        break;
      case 'Monthly':
        dateString = formatDate(selectedDate, 'YYYY-MM');
        break;
      case 'Yearly':
        dateString = formatDate(selectedDate, 'YYYY');
        break;
      case 'Total':
        dateString = 'total'; // Use 'total' as dateString
        break;
      default:
        dateString = formatDate(selectedDate, 'YYYY-MM-DD'); // Default to Daily format
    }

    console.log(baseURL);

    const url = `${baseURL}/api/pv/plants/energy-data?energySystemId=${energySystemId}&date=${dateString}`;

    console.log(url);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('API Response:', data);
        const timeLabels = data.data.infos[0].records.map((record) => record.time);
        this.setState({ timeLabels, rawData: data.data.infos }); // Update state with time labels
        this.convertDataToChartData(data.data);
      })
      .catch((error) => {
        console.error('Fetch Error:', error);
        this.setState({ error, loading: false });
      });
  }

  convertDataToChartData(data) {
    const lastRecordTime = data.infos[0].records[0].time; // Assuming all series have the same record times for simplicity
    this.setState({ selectedTime: lastRecordTime });
    // Example: Summing up the last record of each category to show their distribution at the latest time
    const totals = data.infos.map((info) => {
      // Assuming we're interested in the last record's value for simplicity
      const lastRecord = info.records[info.records.length - 1];

      return {
        label: info.label,
        value: Math.abs(parseFloat(lastRecord.value)), // Use Math.abs to ensure positive values for visualization
      };
    });

    // Filter out categories with zero values to avoid cluttering the pie chart
    const filteredTotals = totals.filter((item) => item.value > 0);

    const chartData = {
      type: 'pie',
      dataPoints: filteredTotals.map((item) => ({
        y: item.value,
        name: item.label,
      })),
    };

    this.setState({ chartData: [chartData], loading: false });
  }

  handleWheel = (event) => {
    event.preventDefault(); // This will prevent the default scroll behavior
    const direction = event.deltaY < 0 ? 'up' : 'down';
    this.updateDataPoint(direction);
  };

  updateDataPoint(direction) {
    const { timeLabels, recordIndex } = this.state;
    let newIndex = recordIndex;

    // 'up' should decrement the index, 'down' should increment the index
    if (direction === 'up') {
      newIndex = newIndex > 0 ? newIndex - 1 : timeLabels.length - 1;
    } else {
      newIndex = newIndex < timeLabels.length - 1 ? newIndex + 1 : 0;
    }

    // Set the newly calculated index in the state
    this.setState({
      recordIndex: newIndex,
      selectedTime: timeLabels[newIndex],
    });

    // Fetch and update the chart data for the new index
    this.updateChartDataForNewTime(timeLabels[newIndex]);
  }

  updateChartDataForNewTime(newTime) {
    const { rawData, chartTitle } = this.state;
    let newSelectedDate = new Date(this.state.selectedDate);

    if (chartTitle === 'Daily') {
      const [hours, minutes] = newTime.split(':');
      newSelectedDate.setHours(hours, minutes);
    }

    const newDataPoints = this.calculateDataPointsForTime(newTime);

    if (newDataPoints) {
      this.setState({
        chartData: newDataPoints,
        selectedDate: newSelectedDate, // Set the selectedDate state to reflect the new time for Daily chart
      });
    }
  }

  determineDateFromTime(time) {
    // If the chart is not 'Daily', then simply return the current selectedDate
    if (this.props.chartTitle !== 'Daily') {
      return this.state.selectedDate;
    }

    // For 'Daily', construct a new date based on the selected time
    const newDate = new Date(this.state.selectedDate);
    const [hours, minutes] = time.split(':');
    newDate.setHours(parseInt(hours), parseInt(minutes), 0);
    return newDate;
  }

  calculateDataPointsForTime(time) {
    // Find the record that matches the given time across all infos
    const newChartData = this.state.rawData
      .map((info) => {
        const record = info.records.find((record) => record.time === time);
        return record
          ? {
              label: info.label,
              y: Math.abs(parseFloat(record.value)), // Assuming positive values are needed for the chart
              name: info.label,
            }
          : null;
      })
      .filter((record) => record); // Filter out any nulls in case a record wasn't found

    // Return the new data points structured for the pie chart
    return [
      {
        type: 'pie',
        dataPoints: newChartData,
      },
    ];
  }

  render() {
    const { chartData, loading, chartKey, selectedDate, selectedTime } = this.state;

    // Format date based on chartTitle
    let formattedDate;
    switch (this.props.chartTitle) {
      case 'Daily':
        formattedDate = selectedDate.toISOString().split('T')[0]; // YYYY-MM-DD
        break;
      case 'Monthly':
        formattedDate = `${selectedDate.getFullYear()}-${('0' + (selectedDate.getMonth() + 1)).slice(-2)}`; // YYYY-MM
        break;
      case 'Yearly':
        formattedDate = selectedDate.getFullYear().toString(); // YYYY
        break;
      case 'Total':
        formattedDate = 'Total'; // Just show 'Total'
        break;
      default:
        formattedDate = selectedDate.toISOString().split('T')[0]; // Default to Daily format
    }

    let subtitleText = '';
    if (this.props.chartTitle === 'Daily') {
      subtitleText = `As of ${selectedDate.toLocaleDateString()} ${selectedTime}`;
    } else if (this.props.chartTitle === 'Monthly') {
      subtitleText = `As of ${selectedTime}`;
    } else if (this.props.chartTitle === 'Yearly') {
      subtitleText = `As of ${selectedTime}`;
    } else if (this.props.chartTitle === 'Total') {
      subtitleText = `Total ${selectedTime}`;
    }

    if (loading) {
      return <div>Loading chart...</div>;
    }

    if (!chartData) {
      return <div>No data available</div>;
    }

    const options = {
      theme: 'light2',
      animationEnabled: true,
      title: {
        text: `${this.props.chartTitle} Energy Data`,
      },

      subtitles: [
        {
          text: subtitleText, // This will show the date in the correct format
          verticalAlign: 'bottom',
          fontSize: 14,
          dockInsidePlotArea: true,
        },
      ],
      width: 240, // Set a fixed width
      height: 350, // Set a fixed height
      data: chartData,
    };

    return (
      <div className="pie-chart-container" ref={this.chartContainerRef} onWheel={this.handleWheel}>
        <CanvasJSChart options={options} key={chartKey} />
      </div>
    );
  }
}

export default PlantEnergyPieChart;
