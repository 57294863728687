import React from 'react';

function HorseshoeProgressBar({ percentage, loadWattage, consumption }) {
  // Ensure the percentage is not less than 0
  const validPercentage = Math.max(percentage, 0);

  const strokeColor = getStrokeColor(validPercentage);
  const radius = 75;
  const circumference = Math.PI * radius;
  const offset = circumference - (validPercentage / 100) * circumference;
  const horseshoePath = `M 25, 80 a 75,75 0 0,1 150,0`;

  function getStrokeColor(percentage) {
    // Color code reversed, lower percentage is now better (green)
    if (percentage >= 75) return 'red'; // More usage, worse
    if (percentage >= 50) return '#ffd300'; // Medium usage
    if (percentage >= 25) return 'yellow'; // Less usage
    return '#7fff00'; // Minimal usage, best
  }

  return (
    <svg width="300" height="200" viewBox="0 0 200 100">
      {/* Background path */}
      {/* <path d={horseshoePath} fill="none" stroke="#ddd" strokeWidth="20" /> */}
      {/* Progress path */}
      {/* <path d={horseshoePath} fill="none" stroke={strokeColor} strokeWidth="20" strokeDasharray={circumference} strokeDashoffset={offset} /> */}
      {/* Percentage text */}
      <text x="60" y="75" fontSize="12" fill="#333" textAnchor="middle">
        Current Usage
      </text>
      <text x="60" y="91" fontSize="12" fill="#333" textAnchor="middle">
        Now: {loadWattage} W
      </text>
      <text x="60" y="107" fontSize="12" fill="#333" textAnchor="middle">
        Consumption: {consumption} kWh
      </text>
      {/* Icon */}
      <text className="BatteryLoad" x="65" y="60" fontSize="30" fill="var(--backff6970)" textAnchor="middle">
        
      </text>
    </svg>
  );
}

export default HorseshoeProgressBar;
