import React, { useEffect, useRef, useState } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import './EnergySystem.css';
import PlantEnergyChart from '../../components/PlantEnergyChart/PlantEnergyChart';
import PowerFlow from '../../components/PowerFlow/PowerFlow';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import EnergySystemMap from '../../components/EnergySystemMap/EnergySystemMap';
import EnergySystemInfo from '../../components/EnergySystemInfo/EnergySystemInfo';
import Chart from 'chart.js/auto'; // This import is necessary to register the pie chart type in Chart.js 3+
import PlantEnergyPieChart from '../../components/PlantEnergyPieChart/PlantEnergyPieChart';
import { options, centerTextPlugin } from './EnergySystemChartOptions';
import { fetchWeatherData, fetchGenerationUseData, fetchRealtimeData, fetchEnergySystemDetails, fetchEnergyFlowData, fetchCommunityDetails } from '../../services/api';

function EnergySystem({ baseURL }) {
  const [energysys, setEnergySystem] = useState(null);
  const [communityDetails, setCommunityDetails] = useState(null); // New state for community details
  const [realtimeData, setRealtimeData] = useState(null); // New state for realtime data
  const [activeTab, setActiveTab] = useState('details'); // State to manage active tab
  const chartContainerRef = useRef(null); // Use useRef hook for the chart container
  const [weather, setWeather] = useState(null); // New state for weather data
  const [energyFlow, setEnergyFlow] = useState(null); // New state for energy flow data
  const location = useLocation(); // This is the hook to get location object
  const { communityId, energySystemId } = useParams(); // Destructure the parameters from the URL
  const [generationUseData, setGenerationUseData] = useState({
    datasets: [],
  });
  const [consumption, setConsumption] = useState(null);

  useEffect(() => {
    fetchGenerationUseData(energySystemId)
      .then((data) => {
        setConsumption(data.consumption);
        setGenerationUseData(data.generationUseData);
      })
      .catch((error) => console.error('There was an error fetching the data', error));
  }, [energySystemId]); // Assuming energySystemId is a dependency for this effect

  // Register the plugin
  Chart.register(centerTextPlugin);

  useEffect(() => {
    // console.log('The current URL is:', location.pathname + location.search);
    // ... other useEffect code ...
  }, [location]); // Add location to the dependency array

  useEffect(() => {
    if (energySystemId) {
      fetchEnergySystemDetails(energySystemId)
        .then((details) => {
          setEnergySystem(details); // Assuming you have a state setter for energy system details
        })
        .catch((error) => console.error('Failed to fetch energy system details:', error));

      fetchRealtimeData(energySystemId)
        .then((data) => {
          setRealtimeData(data); // Assuming you have a state setter for real-time data
        })
        .catch((error) => console.error('Failed to fetch real-time data:', error));
    }
  }, [energySystemId]); // Dependency array to ensure useEffect is called when energySystemId changes

  useEffect(() => {
    // New useEffect hook for fetching weather data
    const fetchWeatherDataForEnergySystem = async () => {
      console.log(energysys);
      if (energysys?.address) {
        const matches = energysys.address.match(/\b\d{5}\b(?=,.*?$)/);
        const zipcode = matches && matches.length > 0 ? matches[0] : null;
        if (zipcode) {
          const weatherData = await fetchWeatherData(zipcode);
          setWeather(weatherData.current);
        }
      }
    };

    fetchWeatherDataForEnergySystem();
  }, [energysys]);

  useEffect(() => {
    if (communityId) {
      fetchCommunityDetails(communityId)
        .then((details) => {
          setCommunityDetails(details); // Assuming you have a state setter for community details
        })
        .catch((error) => console.error('Failed to fetch community details:', error));
    }
  }, [communityId]); // Dependency array ensures useEffect is called when communityId changes

  useEffect(() => {
    fetchEnergyFlowData(energySystemId)
      .then((data) => {
        setEnergyFlow(data);
      })
      .catch((error) => console.error('Failed to fetch energy flow data:', error));
  }, [energySystemId]);

  // Function to calculate the total wattage in the battery
  const calculateBatteryWattage = () => {
    console.log(energyFlow);
    if (realtimeData && energyFlow) {
      const totalWatts = realtimeData.totalPower * 1000; // Convert kWh to Watts
      const batteryWattage = totalWatts * (energyFlow.soc / 100);
      console.log(`Battery Wattage: ${batteryWattage}`);
      return batteryWattage / 1000;
    }
    console.log(`Battery 0?`);
    return 0;
  };

  // Function to calculate charge after load
  const calculateChargeAfterLoad = () => {
    if (energyFlow) {
      const solarPower = energyFlow.pvPower || 0;
      const loadPower = energyFlow.loadOrEpsPower || 0;
      return solarPower - loadPower; // This will calculate the charge after the load
    }
    return 'Loading...'; // You can return a loading state or 0 or any other placeholder value
  };

  const loadWattage = energyFlow ? energyFlow.loadOrEpsPower : 'Loading...';

  useEffect(() => {
    const handleWheel = (event) => {
      // Prevent scrolling when over the chart, but only if the 'Statistics' tab is active
      if (activeTab === 'Statistics') {
        event.preventDefault();
      }
    };

    const chartContainer = chartContainerRef.current;
    if (activeTab === 'Statistics' && chartContainer) {
      chartContainer.addEventListener('wheel', handleWheel, { passive: false });
      return () => {
        chartContainer.removeEventListener('wheel', handleWheel);
      };
    }
  }, [activeTab]); // Dependency on activeTab ensures effect is re-run when it changes

  // Function to change the active tab
  const changeTab = (tabName) => {
    setActiveTab(tabName);
  };

  function getBatteryColor(percentage) {
    if (percentage > 70) return 'var(--ThemeBack0c0)'; // Green
    if (percentage > 50) return 'var(--backf2ff3d)'; // Yellow
    if (percentage > 25) return 'var(--Colorffb93d)'; // Orange
    return 'var(--ColorRed)'; // Red
  }

  if (!energysys || !communityDetails) {
    return <div>Loading...</div>;
  }

  if (!energysys) {
    return <div>Loading...</div>;
  }

  return (
    <div className="energysys-Container">
      <div className="energysys-Inner">
        {/* Tab navigation */}
        <div className="tabs">
          <button onClick={() => changeTab('details')} className={activeTab === 'details' ? 'active' : ''}>
            Details
          </button>
          <button onClick={() => changeTab('Statistics')} className={activeTab === 'Statistics' ? 'active' : ''}>
            Statistics
          </button>
          <button onClick={() => changeTab('map')} className={activeTab === 'map' ? 'active' : ''}>
            Map
          </button>

          {/* Add more tabs as needed */}
        </div>

        {/* Conditional rendering based on active tab */}
        {activeTab === 'details' && (
          <div className="tab-content">
            {/* Details content goes here */}
            <div className="energysys-Titles">
              <h1>STATUS</h1>
              {/* <h1 className="energysys-Name">{energysys.name}</h1>
              <h1 className="energysys-SubName">{energysys.subname}</h1> */}
            </div>
            <div className="energysys-SubInner">
              {/* Display energysys details */}
              <div className="Energy-System">
                <EnergySystemInfo
                  energyFlow={energyFlow}
                  realtimeData={realtimeData}
                  generationUseData={generationUseData}
                  options={options}
                  weather={weather}
                  loadWattage={loadWattage} // Ensure this is structured correctly
                  consumption={consumption}
                  calculateBatteryWattage={calculateBatteryWattage}
                  getBatteryColor={getBatteryColor}
                />
              </div>
            </div>
            <div className="energysys-SubInner">
              <PowerFlow energyFlow={energyFlow} />
            </div>
            <div className="energysys-Titles"></div>
            <div className="energysys-SubInner-CD">
              {/* Display energysys details */}
              <div className="card">
                <div className="card-content">
                  <div className="card-section">
                    <h4>Community Details</h4>
                    <p>
                      <strong>Community:</strong> {communityDetails.name}
                    </p>
                    <p>
                      <strong>Energy System:</strong> {energysys.name}
                    </p>
                    <p>
                      <strong>Construction Manager:</strong> Bob Ross
                    </p>
                    <p>
                      <strong>Address:</strong> {energysys.address}
                    </p>
                  </div>
                  <div className="card-section">
                    <h4>Service Information</h4>
                    <p>
                      <strong>Installer:</strong> Bob Ross' Brother
                    </p>
                    <p>
                      <strong>Last Serviced:</strong> 01/02/2023
                    </p>
                    <p>
                      <strong>Technician:</strong> Bob Ross' Uncle
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'Statistics' && (
          <div className="tab-content" ref={chartContainerRef}>
            {/* <h1>INSERT GRAPHS AND REPORTS HERE</h1> */}
            <PlantEnergyChart energySystemId={energySystemId} baseURL={baseURL} />
            <div className="pie-charts-container">
              <PlantEnergyPieChart chartTitle="Daily" energySystemId={energySystemId} baseURL={baseURL} />
              <PlantEnergyPieChart chartTitle="Monthly" energySystemId={energySystemId} baseURL={baseURL} />
              <PlantEnergyPieChart chartTitle="Yearly" energySystemId={energySystemId} baseURL={baseURL} />
              <PlantEnergyPieChart chartTitle="Total" energySystemId={energySystemId} baseURL={baseURL} />
            </div>
          </div>
        )}
        {activeTab === 'map' && (
          <div className="tab-content">
            {/* Assume energysys has latitude and longitude properties */}
            {energysys && (
              <EnergySystemMap
                energySystems={[
                  {
                    ...energysys,
                    additionalInfo: `Community: ${communityDetails ? communityDetails.name : ''}`,
                  },
                ]}
              />
            )}{' '}
          </div>
        )}
      </div>
      <div className="Right-Side">
        <div className="energy-systems-section">
          <div className="camera-system-card">
            <h1 className="energy-systems-title">CAMERAS</h1>
            {Array.from({ length: 4 }, (_, i) => i + 1).map((channel) => {
              // Construct the stream URL dynamically for each camera channel
              const baseStreamUrl = 'rtsp://admin:Welcome100!@103.195.101.92:554/cam/realmonitor?'; // Base part of your stream URL
              const streamUrlWithChannel = `${baseStreamUrl}channel=${channel}&subtype=1`; // Append the dynamic channel part
              return (
                <div key={channel} className="video-container">
                  {/* <h2>Camera {channel}</h2> */}
                  <VideoPlayer wsUrl={`ws://dashapi.clearvision.gg:${10000 + channel}`} streamUrl={streamUrlWithChannel} channel={channel} baseURL={baseURL} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnergySystem;
