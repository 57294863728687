import React, { useState, useEffect } from 'react';
import { fetchUsers, fetchPlantHistory, fetchPlantHistoryEvents, updatePlantHistory, createPlantHistory } from '../../services/api';

function EnergySystemHistoryForm({ energySystemId, communities }) {
  const [historyData, setHistoryData] = useState({
    Event: '',
    EventDate: '',
    CommunityId: '',
    UserId: '',
  });
  const [users, setUsers] = useState([]); // State to store user data
  const [creating, setCreating] = useState(false);
  const [showProblems, setShowProblems] = useState(false);
  const [problemDescription, setProblemDescription] = useState('');
  const [historyList, setHistoryList] = useState([]);
  const [historyEvents, setHistoryEvents] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  // Function to fetch users from API
  const fetchUserData = async () => {
    try {
      const data = await fetchUsers();
      setUsers(data); // Assuming data is the array of users
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const fetchHistory = async () => {
    try {
      const response = await fetchPlantHistory(energySystemId);
      setHistoryList(response);
    } catch (error) {
      console.error('Failed to fetch energy system history:', error);
    }
  };

  const fetchHistoryEvents = async () => {
    try {
      const events = await fetchPlantHistoryEvents();
      setHistoryEvents(events);
    } catch (error) {
      console.error('Failed to fetch energy system history events:', error);
    }
  };

  useEffect(() => {
    fetchUserData(); // Fetch users when the component mounts
    fetchHistoryEvents();
    if (energySystemId) {
      fetchHistory();
    }
  }, [energySystemId]);

  const handleEdit = (index) => {
    const selectedHistory = historyList[index];
    setEditingIndex(index);
    setHistoryData({
      Event: selectedHistory[2],
      EventDate: selectedHistory[3].split('/').reverse().join('-'), // Adjust date format to 'yyyy-mm-dd'
      CommunityId: communities.find((c) => c.name === selectedHistory[4])?.id || '', // Find communityId by name
      UserId: selectedHistory[5], // Assume it's the ID already
    });
  };

  const handleSave = async (index) => {
    const refId = historyList[index][1]; // Get refId from the row
    try {
      await updatePlantHistory(refId, { ...historyData });
      alert('History updated successfully');
      setEditingIndex(null); // Exit editing mode
      fetchHistory(); // Re-fetch history to show updated data
    } catch (error) {
      console.error('Error updating energy system history:', error);
      alert('Failed to update event');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (creating) {
      try {
        await createPlantHistory(energySystemId, { ...historyData });
        alert('History added successfully');
        setCreating(false);
        fetchHistory();
      } catch (error) {
        console.error('Error creating energy system history:', error);
        alert('Failed to create event');
      }
    }
  };

  return (
    <div>
      <form className="history-form" onSubmit={handleSubmit}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className="id-column">ID</th>
              <th className="ref-column">REF#</th>
              <th>Event</th>
              <th>Date</th>
              <th>Community</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>
            {creating && (
              <tr>
                <td></td>
                <td>New</td>
                <td>Auto</td>
                <td>
                  <select name="Event" value={historyData.Event} onChange={(e) => setHistoryData({ ...historyData, Event: e.target.value })} required>
                    <option value="">Select Event</option>
                    {historyEvents.map((Event, index) => (
                      <option key={index} value={Event}>
                        {Event}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input type="date" name="EventDate" value={historyData.EventDate} onChange={(e) => setHistoryData({ ...historyData, EventDate: e.target.value })} required />
                </td>
                <td>
                  <select name="CommunityId" value={historyData.CommunityId} onChange={(e) => setHistoryData({ ...historyData, CommunityId: e.target.value })} required>
                    <option value="">Select Community</option>
                    {communities.map((community) => (
                      <option key={community.id} value={community.id}>
                        {community.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select name="UserId" value={historyData.UserId} onChange={(e) => setHistoryData({ ...historyData, UserId: e.target.value })} required>
                    <option value="">Select User</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            )}
            {historyList.map((item, index) => (
              <tr key={index}>
                <td>
                  {editingIndex !== index && (
                    <button onClick={() => handleEdit(index)}>🖉</button> // Edit Icon
                  )}
                </td>
                <td>{item[0]}</td> {/* ID */}
                <td>{item[1]}</td> {/* REF# */}
                <td>
                  {editingIndex === index ? (
                    <select name="Event" value={historyData.Event} onChange={(e) => setHistoryData({ ...historyData, Event: e.target.value })} required>
                      <option value="">Select Event</option>
                      {historyEvents.map((event) => (
                        <option key={event} value={event}>
                          {event}
                        </option>
                      ))}
                    </select>
                  ) : (
                    item[2] // Event
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <input type="date" name="EventDate" value={historyData.EventDate} onChange={(e) => setHistoryData({ ...historyData, EventDate: e.target.value })} required />
                  ) : (
                    item[3] // Date
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <select name="communityId" value={historyData.CommunityId} onChange={(e) => setHistoryData({ ...historyData, CommunityId: e.target.value })} required>
                      <option value="">Select Community</option>
                      {communities.map((community) => (
                        <option key={community.id} value={community.id}>
                          {community.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    item[4] // Community
                  )}
                </td>
                <td>
                  {editingIndex === index ? (
                    <select name="UserId" value={historyData.UserId} onChange={(e) => setHistoryData({ ...historyData, UserId: e.target.value })} required>
                      <option value="">Select User</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                  ) : (
                    item[5] // User
                  )}
                </td>
                {editingIndex === index && (
                  <td>
                    <button onClick={() => handleSave(index)}>Save</button>
                    <button onClick={() => setEditingIndex(null)}>Cancel</button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <button type="button" onClick={() => setCreating(!creating)}>
            New Event
          </button>
          <button type="button" onClick={() => setShowProblems(!showProblems)}>
            Problems
          </button>
          <button type="submit" disabled={!creating}>
            Create Event
          </button>
        </div>
      </form>
      {showProblems && (
        <div>
          <input type="text" placeholder="Describe the problem" value={problemDescription} onChange={(e) => setProblemDescription(e.target.value)} />
        </div>
      )}
    </div>
  );
}

export default EnergySystemHistoryForm;
