import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchCommunities } from '../../services/api';

const CommunitiesContext = createContext();

export const CommunitiesProvider = ({ children }) => {
  const [communities, setCommunities] = useState([]);

  useEffect(() => {
    const init = async () => {
      const data = await fetchCommunities();
      setCommunities(data);
    };
    init();
  }, []);

  return <CommunitiesContext.Provider value={{ communities, setCommunities }}>{children}</CommunitiesContext.Provider>;
};

export const useCommunities = () => useContext(CommunitiesContext);
