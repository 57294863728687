import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const mapContainerStyle = {
  height: '800px',
  width: '100%',
  borderRadius: '20px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const EnergySystemMap = ({ energySystems }) => {
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [map, setMap] = useState(null);

  // Adjust bounds to include all markers
  useEffect(() => {
    if (map && energySystems.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      energySystems.forEach((system) => bounds.extend({ lat: system.latitude, lng: system.longitude }));
      map.fitBounds(bounds);
      if (energySystems.length === 1) {
        map.setZoom(16); // Adjust zoom for a single marker
      }
    }
  }, [map, energySystems]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyA7oK-vK9ssx6bXu9Yv_5ZnJV0r0i2YDdo">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        onLoad={setMap} // Set the map instance
        options={options}
      >
        {energySystems.map((system, index) => (
          <Marker key={index} position={{ lat: system.latitude, lng: system.longitude }} onClick={() => setSelectedSystem(system)} />
        ))}

        {selectedSystem && (
          <InfoWindow position={{ lat: selectedSystem.latitude, lng: selectedSystem.longitude }} onCloseClick={() => setSelectedSystem(null)}>
            <div>
              <h2>{selectedSystem.name}</h2>
              <div>{selectedSystem.address}</div>
              <div>{selectedSystem.additionalInfo}</div>
              {/* Include any other information you want to display */}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default EnergySystemMap;
