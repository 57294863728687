// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LoginSystem.css */

.login-system {
  max-width: 300px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-form .form-group {
  margin-bottom: 15px;
  width: 100%;
}

.login-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.login-form .form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #5c6bc0;
  color: white;
  cursor: pointer;
}

.login-button:hover {
  background-color: #3f51b5;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoginSystem/LoginSystem.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* LoginSystem.css */\n\n.login-system {\n  max-width: 300px;\n  margin: 50px auto;\n  padding: 20px;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n}\n\n.login-form .form-group {\n  margin-bottom: 15px;\n  width: 100%;\n}\n\n.login-form .form-group label {\n  display: block;\n  margin-bottom: 5px;\n}\n\n.login-form .form-group input {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  box-sizing: border-box;\n}\n\n.login-button {\n  width: 100%;\n  padding: 10px;\n  border: none;\n  border-radius: 4px;\n  background-color: #5c6bc0;\n  color: white;\n  cursor: pointer;\n}\n\n.login-button:hover {\n  background-color: #3f51b5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
