// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Assuming this is in PlantEnergyPieChart.css */
.pie-charts-container {
  display: flex;
  flex-wrap: nowrap; /* Make sure charts do not wrap */
  justify-content: center; /* Center the charts horizontally */
  align-items: flex-start; /* Align charts to the top */
  gap: 20px; /* Space between charts */
}

.pie-chart-container {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 20px); /* Adjust the percentage based on the number of charts and the gap size */
  margin-bottom: 30px; /* Space below the chart for the subtitle */
}
`, "",{"version":3,"sources":["webpack://./src/components/PlantEnergyPieChart/PlantEnergyPieChart.css"],"names":[],"mappings":"AAAA,gDAAgD;AAChD;EACE,aAAa;EACb,iBAAiB,EAAE,iCAAiC;EACpD,uBAAuB,EAAE,mCAAmC;EAC5D,uBAAuB,EAAE,4BAA4B;EACrD,SAAS,EAAE,yBAAyB;AACtC;;AAEA;EACE,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B,EAAE,yEAAyE;EACrG,mBAAmB,EAAE,2CAA2C;AAClE","sourcesContent":["/* Assuming this is in PlantEnergyPieChart.css */\n.pie-charts-container {\n  display: flex;\n  flex-wrap: nowrap; /* Make sure charts do not wrap */\n  justify-content: center; /* Center the charts horizontally */\n  align-items: flex-start; /* Align charts to the top */\n  gap: 20px; /* Space between charts */\n}\n\n.pie-chart-container {\n  flex: 0 0 calc(25% - 20px); /* Adjust the percentage based on the number of charts and the gap size */\n  margin-bottom: 30px; /* Space below the chart for the subtitle */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
