import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios'; // Make sure to install axios with `npm install axios`
import './LoginSystem.css';

function LoginSystem({ onLoginSuccess, baseURL }) {
  // Login state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Registration state
  const [registerUsername, setRegisterUsername] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  // Toggle state
  const [isLogin, setIsLogin] = useState(true);

  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/api/login`, { username, password });
      console.log('Logged in:', response.data);
      localStorage.setItem('token', response.data.token);
      onLoginSuccess(); // Notify the App component about the successful login
      navigate('/communities');
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Call the register API
      const response = await axios.post(`${baseURL}/api/register`, {
        username: registerUsername,
        password: registerPassword,
        email,
        phone,
      });
      console.log('Registered:', response.data);

      // Show an alert to the user
      alert('Registration successful! You can now log in.');

      // Reset registration form (optional)
      setRegisterUsername('');
      setRegisterPassword('');
      setEmail('');
      setPhone('');

      // Switch to login view
      setIsLogin(true);
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
    }
  };

  return (
    <div className="login-system">
      {isLogin ? (
        // Login Form
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
          <p onClick={() => setIsLogin(false)}>Don't have an account? Register here</p>
        </form>
      ) : (
        // Registration Form
        <form onSubmit={handleRegister} className="register-form">
          <div className="form-group">
            <label htmlFor="registerUsername">Username</label>
            <input type="text" id="registerUsername" value={registerUsername} onChange={(e) => setRegisterUsername(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="registerPassword">Password</label>
            <input type="password" id="registerPassword" value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input type="tel" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
          </div>
          <button type="submit" className="register-button">
            Register
          </button>
          <p onClick={() => setIsLogin(true)}>Already have an account? Login here</p>
        </form>
      )}
    </div>
  );
}

export default LoginSystem;
