import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios if not already imported
import Select from 'react-select';

const CommunityList = ({ communities, communityUsers, communityPlants, handleEdit, handleDelete, onRemoveUserFromCommunity, onRemovePlantFromCommunity, baseURL }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredCommunities, setFilteredCommunities] = useState(communities);

  useEffect(() => {
    // When communityUsers or communityPlants change, this effect will run, ensuring the component has the latest props.
    setFilteredCommunities(communities); // Update filteredCommunities if necessary
  }, [communities, communityUsers, communityPlants]); // Add communityUsers and communityPlants as dependencies

  useEffect(() => {
    if (communities.length > 0) {
    }
  }, [communities, baseURL]);

  useEffect(() => {
    let filtered = communities;
    if (searchTerm) {
      const lowercasedFilter = searchTerm.toLowerCase();
      filtered = communities.filter((community) => community.name.toLowerCase().includes(lowercasedFilter));
    }

    if (selectedOption) {
      filtered = [communities.find((community) => community.id === selectedOption.value)];
    }

    setFilteredCommunities(filtered);
  }, [searchTerm, selectedOption, communities]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedOption(null);
  };

  const handleDropdownChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSearchTerm('');
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSelectedOption(null);
  };

  return (
    <div className="list-container">
      <h3>Community List</h3>
      <div className="search-and-filter">
        <input type="text" placeholder="Search communities..." value={searchTerm} onChange={handleSearchChange} className="search-input" />
        <div className="dropdown-and-clear">
          <Select options={communities.map((community) => ({ value: community.id, label: community.name }))} onChange={handleDropdownChange} value={selectedOption} className="community-select" placeholder="Filter by name" />
          <button onClick={handleClearSearch} className="clear-button">
            Clear
          </button>
        </div>
      </div>
      <ul>
        {filteredCommunities.map((community) => (
          <li key={community.id}>
            <div className="community-details">
              <div className="community-name">{community.name}</div>
              <div className="community-division">{community.subtitle}</div>
              <div className="community-address">{community.address}</div>
              {/* Display users for the current community */}
              <div className="community-users">
                {communityUsers[community.id]?.map((user) => (
                  <div className="user-tag" key={user.id}>
                    <span className="user-name">{user.username}</span>
                    <button onClick={() => onRemoveUserFromCommunity(community.id, user.id)}>x</button>
                  </div>
                ))}
              </div>
              {/* Display plants for the current community */}
              <div className="community-plants">
                {communityPlants[community.id]?.map((plant) => (
                  <div className="plant-tag" key={plant.id}>
                    <span className="plant-name">{plant.name}</span>
                    <button onClick={() => onRemovePlantFromCommunity(community.id, plant.id)}>x</button>
                  </div>
                ))}
              </div>
            </div>
            <div className="button-group">
              <button onClick={() => handleEdit(community)}>
                <i className="fas fa-pencil-alt"></i>
              </button>
              <button onClick={() => handleDelete(community.id)}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CommunityList;
