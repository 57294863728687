// Community.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CommunityCard.css'; // Make sure to create the corresponding CSS file
import { Link } from 'react-router-dom';
import { getDailyEnergyPVPro, getDailyEnergyEG4, fetchEnergySystemsForCommunity, fetchGenerationUseData } from '../../services/api';

const Community = ({ community }) => {
  const [totalEnergy, setTotalEnergy] = useState(0);
  const [totalConsumption, setTotalConsumption] = useState(0);
  const navigate = useNavigate();

  const handleNavigate = (v, event) => {
    event.preventDefault();
    navigate('/' + v);
  };

  const getCurrentDateObject = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-indexed in JavaScript
    const year = currentDate.getFullYear();

    return { day, month, year };
  };

  useEffect(() => {
    async function fetchData() {
      const es = await fetchEnergySystemsForCommunity(community);

      for (const element of es) {
        let data;
        let consumption;
        const date = getCurrentDateObject();

        try {
          // PVPRO = 1, EG4 = 2
          if (element.type === 1) {
            const response = await getDailyEnergyPVPro(element.id);
            data = parseInt(response.data?.realtime.etoday) || 0;
          } else {
            const response = await getDailyEnergyEG4(element.id, date.year, date.month, 'ePvAllDay');
            data = parseInt(response.energy) || 0;
          }
          const responseConsumption = await fetchGenerationUseData(element.id);
          consumption = parseInt(responseConsumption.consumption) || 0;
        } catch (error) {
          data = 0; // default to 0 if there is any error fetching data
          consumption = 0; // default to 0 if there is any error fetching consumption data
          console.error(`Error fetching data for element ID ${element.id}:`, error);
        }

        setTotalEnergy((prevTotal) => prevTotal + data);
        setTotalConsumption((prevTotal) => prevTotal + consumption);
        console.log(`Fetched data for element ID ${element.id}: ${data}, consumption: ${consumption}`);
      }
    }

    fetchData();
  }, [community]);

  return (
    <div className="community-card">
      <div className="community-image" onClick={(e) => handleNavigate(community.url, e)}>
        <img src={community.image} alt={`${community.name} community`} />
      </div>
      <div className="community-info">
        <div className="community-titles">
          <h3 className="community-title" onClick={(e) => handleNavigate(community.url, e)}>
            {community.name}
          </h3>
          <p className="community-subtitle" onClick={(e) => handleNavigate(community.url, e)}>
            {community.subtitle}
          </p>
        </div>
        <Link to={`/Community/${community.id}`}>View community systems</Link>
        <p className="community-system-total">Total number of energy systems: </p>
        <p className="community-system-number">{community.systemsCount}</p>
        <svg width="200" height="500" viewBox="0 0 100 140">
          <circle cx="70" cy="70" r="65" stroke="black" strokeWidth="3" fill="#0be300" />
          <circle cx="70" cy="85" r="50" stroke="black" strokeWidth="2" fill="#787878" />
          <text x="70" y="20" textAnchor="middle" fontSize="10" fill="black">
            {totalEnergy} kWh
          </text>
          <text x="70" y="32" textAnchor="middle" fontSize="10" fill="black">
            Generated
          </text>
          <text x="70" y="70" textAnchor="middle" fontSize="10" fill="black">
            {totalConsumption} kWh
          </text>
          <text x="70" y="82" textAnchor="middle" fontSize="10" fill="black">
            Used
          </text>
        </svg>
      </div>
    </div>
  );
};

export default Community;
