// Import Chart from chart.js if needed
import Chart from 'chart.js/auto'; // This import is necessary to register the pie chart type in Chart.js 3+

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        generateLabels: function (chart) {
          // Use the default generateLabels function to get the default labels
          const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
          const labelsOriginal = original.call(this, chart);

          // Modify the labels to add 'kWh'
          return labelsOriginal.map((label) => ({
            ...label,
            text: `${label.text}: ${chart.data.datasets[0].data[label.index]} kWh`,
          }));
        },
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed !== null) {
            label += `${context.parsed} kWh`;
          }
          return label;
        },
      },
    },
    centerText: {
      display: true,
      text: '', // Default text, will be updated via the chart's data
    },
  },
  elements: {
    center: {
      text: 'E-Today', // Default text, will be updated dynamically
      color: '#FF6384', // Default color, can be dynamic or static
      fontStyle: 'Arial', // Default font, can be dynamic or static
      sidePadding: 20, // Default padding for text
    },
  },
};

const centerTextPlugin = {
  id: 'centerText',
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    const text = chart.data.text || '';
    const { width, height } = chart;
    const fontSize = (height / 114).toFixed(2);
    ctx.font = `${fontSize}em sans-serif`;
    ctx.textBaseline = 'middle';
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;
    ctx.fillText(text, textX, textY);
  },
};

// Export the options and centerTextPlugin
export { options, centerTextPlugin };
