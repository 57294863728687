import { useState, useEffect } from 'react';
import { fetchEnergySystems } from '../../services/api';

export function useEnergySystems() {
  const [energySystems, setEnergySystems] = useState([]);

  useEffect(() => {
    const loadEnergySystems = async () => {
      const systems = await fetchEnergySystems();
      setEnergySystems(systems);
    };

    loadEnergySystems();
  }, []);

  return [energySystems, setEnergySystems]; // Return both the state and the updater function
}
