import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

function EnergySystemDetails({ energySystems, baseURL, updateEnergySystem }) {
  const [editingEnergySystem, setEditingEnergySystem] = useState(null);
  const [energySystemEditFormData, setEnergySystemEditFormData] = useState({
    name: '',
    existCamera: '',
    thumbUrl: '',
    address: '',
    email: '',
    phone: '',
  });

  // Load energy system details into form when a system is selected for editing
  useEffect(() => {
    if (editingEnergySystem) {
      setEnergySystemEditFormData({
        name: editingEnergySystem.name || '',
        existCamera: editingEnergySystem.existCamera || '',
        thumbUrl: editingEnergySystem.thumbUrl || '',
        address: editingEnergySystem.address || '',
        email: editingEnergySystem.email || '',
        phone: editingEnergySystem.phone || '',
      });
    }
  }, [editingEnergySystem]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEnergySystemEditFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEnergySystemSelect = (selectedOption) => {
    const system = energySystems.find((sys) => sys.id === selectedOption.value);
    setEditingEnergySystem(system);
  };

  const handleEnergySystemEditSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateEnergySystem(editingEnergySystem.id, energySystemEditFormData);
    } catch (error) {
      console.error('Error updating energy system:', error);
    }
  };

  return (
    <div className="energy-system-details-container">
      <h3>Energy System Details</h3>
      <Select classNamePrefix="react-select" placeholder="Select Energy System to Edit" options={energySystems.map((system) => ({ label: system.name, value: system.id }))} onChange={handleEnergySystemSelect} value={editingEnergySystem ? { label: editingEnergySystem.name, value: editingEnergySystem.id } : null} />
      {editingEnergySystem && (
        <form onSubmit={handleEnergySystemEditSubmit} className="energy-system-edit-form">
          <div className="details-row">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={energySystemEditFormData.name} onChange={handleInputChange} />
          </div>
          <div className="details-row">
            <label htmlFor="existCamera">Camera URL:</label>
            <input type="text" id="existCamera" name="existCamera" value={energySystemEditFormData.existCamera} onChange={handleInputChange} />
          </div>
          <div className="details-row">
            <label htmlFor="thumbUrl">Energy System Picture:</label>
            <input type="text" id="thumbUrl" name="thumbUrl" value={energySystemEditFormData.thumbUrl} onChange={handleInputChange} />
          </div>
          <div className="details-row">
            <label htmlFor="address">Address:</label>
            <input type="text" id="address" name="address" value={energySystemEditFormData.address} onChange={handleInputChange} />
          </div>
          <div className="details-row">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={energySystemEditFormData.email} onChange={handleInputChange} />
          </div>
          <div className="details-row">
            <label htmlFor="phone">Phone:</label>
            <input type="tel" id="phone" name="phone" value={energySystemEditFormData.phone} onChange={handleInputChange} />
          </div>
          <button type="submit" className="submit-button">
            Save Changes
          </button>
        </form>
      )}
    </div>
  );
}

export default EnergySystemDetails;
