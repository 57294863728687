import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginSystem from '../../components/LoginSystem/LoginSystem'; // Adjust the import path as necessary
import './Home.css';

function Home({ onLoginSuccess, baseURL }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      onLoginSuccess(); // Update the state in the App component
      navigate('/communities');
    }
  }, [navigate, onLoginSuccess]);

  const isLoggedIn = localStorage.getItem('token') !== null;

  return (
    <div className="home-container">
      {isLoggedIn && <h1>Welcome to the Dashboard</h1>}
      <h1 className="home-title">I-BEC</h1>
      {!isLoggedIn && <LoginSystem baseURL={baseURL} onLoginSuccess={onLoginSuccess} />}
      {/* ... other content ... */}
    </div>
  );
}

export default Home;
