import React, { useState, useEffect } from 'react';

function CommunityForm({ currentCommunity, saveCommunity }) {
  // Initial form state can either be empty or populated based on currentCommunity
  const initialFormState = {
    name: currentCommunity?.name || '',
    subtitle: currentCommunity?.subtitle || '',
    address: currentCommunity?.address || '',
    description: currentCommunity?.description || '',
    image: currentCommunity?.image || '',
  };

  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    setFormData({
      name: currentCommunity?.name || '',
      subtitle: currentCommunity?.subtitle || '',
      address: currentCommunity?.address || '',
      description: currentCommunity?.description || '',
      image: currentCommunity?.image || '',
    });
  }, [currentCommunity]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // The saveCommunity function will decide to create or update based on if currentCommunity.id exists
      const response = await saveCommunity(formData, currentCommunity?.id);
      console.log('Community saved successfully:', response);
      // Here, you can add additional post-save logic such as navigating away, displaying a success message, etc.
    } catch (error) {
      console.error('Failed to save community:', error);
    }
  };

  return (
    <div className="form-container">
      <h3>Community Details</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Community Name:
            <input type="text" name="name" value={formData.name} onChange={handleFormChange} required />
          </label>
        </div>
        <div className="form-group">
          <label>
            Community Division:
            <input type="text" name="subtitle" value={formData.subtitle} onChange={handleFormChange} />
          </label>
        </div>
        <div className="form-group">
          <label>
            Address:
            <input type="text" name="address" value={formData.address} onChange={handleFormChange} />
          </label>
        </div>
        <div className="form-group">
          <label>
            Description:
            <textarea name="description" value={formData.description} onChange={handleFormChange} />
          </label>
        </div>
        <div className="form-group">
          <label>
            Image:
            <input type="text" name="image" value={formData.image} onChange={handleFormChange} />
          </label>
        </div>
        <div className="button-group">
          <button type="submit">{currentCommunity ? 'Update' : 'Create'}</button>
        </div>
      </form>
    </div>
  );
}

export default CommunityForm;
