import React from 'react';
import './EnergySystemMainCard.css'; // Make sure this CSS file is updated for new elements
import { Link } from 'react-router-dom';

const EnergySystemMainCard = ({ system }) => {
  // Helper to format the system status for display
  const formatStatus = (status) => (status === 1 ? 'Active' : 'Inactive');

  // Format numbers with commas as thousands separators
  const formatNumber = (number) => new Intl.NumberFormat('en-US').format(number);

  // Image error handling
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevent endless loop
    e.target.src = 'path/to/your/default-image.png'; // Placeholder image
  };

  console.log(`system type: ${JSON.stringify(system)}`);

  return (
    <div className="energy-system-card-es">
      <img src={system.thumbUrl} alt={`Energy system: ${system.name}`} className="system-image" onError={handleImageError} />
      <div className="system-info-es">
        <h3 className="system-title-es">
          <Link to={`/Community/${system.communityid}/energy-system/${system.id}`}>{system.name}</Link>
        </h3>
        <p>
          Community: <Link to={`/Community/${system.communityid}`}>{system.community}</Link>
        </p>
        {system.type == '1' ? <span className="tag tag-pro">PVPRO</span> : <span className="tag tag-pv4">PV4</span>}
        <p>Efficiency: {formatNumber(system.efficiency)}</p>
        <p>Total Energy Generated: {formatNumber(system.etotal)} kWh</p>
        <p>Status: {formatStatus(system.status)}</p>
      </div>
      {system.percentage && <div className="system-percentage">{system.percentage}</div>}
    </div>
  );
};

export default EnergySystemMainCard;
