import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import './PlantEnergyChart.css';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class PlantEnergyChart extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight
    this.state = {
      chartData: null,
      loading: true,
      error: null,
      selectedDate: today,
      chartKey: 0, // Add a key for the chart
    };
    this.chart = React.createRef(); // Create the ref here
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }

  componentDidMount() {
    this.fetchEnergyData();
  }

  componentDidUpdate(prevProps) {
    // If the energy system ID prop has changed, re-fetch data
    if (this.props.energySystemId !== prevProps.energySystemId) {
      this.fetchEnergyData();
    }
  }

  fetchEnergyData() {
    const energySystemId = this.props.energySystemId; // Use props
    const dateString = this.state.selectedDate.toISOString().split('T')[0]; // This will now work
    const baseURL = this.props.baseURL;

    console.log(baseURL);

    const url = `${baseURL}/api/pv/plants/energy-data?energySystemId=${energySystemId}&date=${dateString}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('API Response:', data);
        this.convertDataToChartData(data.data);
      })
      .catch((error) => {
        console.error('Fetch Error:', error);
        this.setState({ error, loading: false });
      });
  }

  projectFutureData(dataPoints, projectionDays) {
    // Simple linear projection based on the rate of change in the last few data points
    const lastIndex = dataPoints.length - 1;
    const lastValue = dataPoints[lastIndex].y;
    const secondLastValue = dataPoints[lastIndex - 1].y;

    // Calculate daily change (this is a very simplistic approach)
    const dailyChange = lastValue - secondLastValue;

    // Project future data
    let projectedData = [];
    for (let i = 1; i <= projectionDays; i++) {
      projectedData.push({
        x: new Date(dataPoints[lastIndex].x.getTime() + i * 24 * 60 * 60 * 1000), // Next day
        y: lastValue + dailyChange * i,
      });
    }

    return projectedData;
  }

  handleProjectData = () => {
    // Ensure we have data to project from
    if (this.state.chartData && this.state.chartData.length > 0) {
      const projectionDays = 7; // Number of days to project into the future

      // Make a copy of the current chart data
      let newChartData = this.state.chartData.map((series) => {
        // If there's no data or just a single data point, we cannot project
        if (!series.dataPoints || series.dataPoints.length <= 1) {
          return series;
        }

        // Calculate the projected data for the current series
        const projectedData = this.projectFutureData(series.dataPoints, projectionDays);

        // Append the projected data to the current series
        return {
          ...series,
          dataPoints: [...series.dataPoints, ...projectedData],
        };
      });

      // Update the state with the new chart data to trigger a re-render
      this.setState({ chartData: newChartData, chartKey: this.state.chartKey + 1 });
    }
  };

  projectFutureData = (dataPoints, projectionDays) => {
    const intervalsPerDay = 288; // 1440 minutes in a day / 5 minutes per interval
    const totalIntervals = intervalsPerDay * projectionDays;
    const lastIndex = dataPoints.length - 1;
    const lastPoint = dataPoints[lastIndex];

    // Calculate the rate of change (slope) based on the last two data points, if applicable
    // This is a simplistic linear projection
    const slope = (dataPoints[lastIndex].y - dataPoints[lastIndex - 1].y) / (dataPoints[lastIndex].x - dataPoints[lastIndex - 1].x);

    let projectedData = [];
    for (let i = 1; i <= totalIntervals; i++) {
      // Project the data point for the next 5-minute interval
      const nextTimestamp = new Date(lastPoint.x.getTime() + i * 5 * 60 * 1000);
      const projectedValue = lastPoint.y + slope * (i * 5 * 60 * 1000);

      projectedData.push({
        x: nextTimestamp,
        y: projectedValue,
      });
    }

    return projectedData;
  };

  convertDataToChartData(data) {
    // Check if 'data' and 'data.infos' are present
    if (!data || !Array.isArray(data.infos)) {
      console.error('Invalid data structure:', data);
      this.setState({ error: new Error('Invalid data structure'), loading: false });
      return;
    }

    // Check if every 'info' has a 'records' array
    const invalidInfo = data.infos.find((info) => !Array.isArray(info.records));
    if (invalidInfo) {
      console.error('Invalid info structure:', invalidInfo);
      this.setState({ error: new Error('Invalid info structure'), loading: false });
      return;
    }

    const chartData = data.infos.map((info) => {
      const dataPoints = info.records.map((record) => {
        const [hour, minute] = record.time.split(':');
        const date = new Date();
        date.setHours(parseInt(hour, 10), parseInt(minute, 10), 0, 0);
        let value = parseFloat(record.value);

        // No need to modify the value for SOC here, it will be handled in yValueFormatString

        return {
          x: date,
          y: value, // Use the raw value for plotting on the chart
          toolTipContent: info.name === 'soc' ? 'SOC: {y}%' : undefined, // Leave other series tooltips as default
        };
      });

      // Determine the axis to use and format string based on the type of data
      let axisYType = 'primary';
      let yValueFormatString = '#,##0.## ' + info.unit;
      if (info.name === 'soc') {
        axisYType = 'secondary';
        // Since the value is already a percentage, no need to add '%' in the format
        yValueFormatString = '#,##0.##';
      }

      return {
        type: 'spline',
        name: info.label,
        showInLegend: true,
        xValueType: 'dateTime',
        xValueFormatString: 'HH:mm',
        yValueFormatString: yValueFormatString,
        axisYType: axisYType,
        dataPoints: dataPoints,
      };
    });

    this.setState({ chartData, loading: false });
  }

  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === 'undefined' || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }

    // Increment the key to force re-render
    this.setState((prevState) => ({ chartKey: prevState.chartKey + 1 }));
  }

  handleDateChange(e) {
    const newDate = new Date(e.target.value + 'T00:00:00'); // Include time part to avoid timezone issues
    this.setState({ selectedDate: newDate }, () => {
      this.fetchEnergyData(); // Fetch data for the new date
    });
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    return [year, month, day].join('-');
  }

  render() {
    const { chartData, loading, selectedDate, chartKey } = this.state;

    // Format the date to YYYY-MM-DD
    const formattedDate = this.formatDate(selectedDate);

    const triggerDatePicker = (e) => {
      e.preventDefault();
      const input = e.target;
      input.blur();
      setTimeout(() => input.focus(), 0);
    };

    if (loading) {
      return <div>Loading chart...</div>;
    }

    if (!chartData) {
      return <div>Loading chart...</div>;
    }

    const options = {
      theme: 'light2',
      animationEnabled: true,
      title: {
        text: 'Plant Energy Generation',
      },
      axisX: {
        title: 'Time of Day',
        valueFormatString: 'HH:mm',
      },
      axisY: {
        title: 'Energy',
        includeZero: false,
      },
      axisY2: {
        title: 'SOC (%)',
        includeZero: false,
        suffix: '%',
        // You can also format this axis specifically for percentages, etc.
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: 'pointer',
        itemclick: this.toggleDataSeries,
      },
      data: this.state.chartData,
    };

    return (
      <div style={{ textAlign: 'right' }}>
        <input type="date" value={formattedDate} onChange={this.handleDateChange.bind(this)} className="date-input" />{' '}
        <button onClick={this.handleProjectData} className="button">
          Project Data
        </button>
        {/* Render the chart only if the data is available */}
        {chartData && <CanvasJSChart options={options} key={chartKey} />}
      </div>
    );
  }
}

export default PlantEnergyChart;
