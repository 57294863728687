// PowerFlow.js
import React from 'react';
import './PowerFlow.css'; // Make sure to create the corresponding CSS file for styling

const PowerFlow = ({ energyFlow }) => {
  const svgWidth = 800;
  const svgHeight = 175;
  const circleRadius = 20;
  const circleYPosition = svgHeight / 2;
  const solarX = circleRadius * 2 + 20;
  const inverterX = svgWidth / 2;
  const loadX = svgWidth - circleRadius * 2 - 20;
  const batteryY = circleYPosition + 40;
  const offsetY = 50; // Offset for the line to not overlap with circles

  // Define the path for the signal to follow
  const signalPath = `M ${solarX},${circleYPosition - offsetY} 
                        L ${inverterX},${circleYPosition - offsetY} 
                        L ${inverterX},${batteryY + offsetY - 75} 
                        M ${inverterX},${circleYPosition - offsetY} 
                        L ${loadX},${circleYPosition - offsetY}`;

  return (
    <svg className="PowerFlow" viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
      {/* Define the invisible path for the signal to follow */}
      <path id="signalPath" d={signalPath} fill="none" stroke="none" />
      {/* Lines connecting Solar to Inverter */}
      <path d={`M ${solarX} ${circleYPosition - 50} L ${inverterX - circleRadius} ${circleYPosition - 50}`} stroke="black" fill="none" />
      {/* Line connecting Inverter to Battery */}
      <path d={`M ${inverterX} ${circleYPosition - 30} L ${inverterX} ${batteryY - circleRadius}`} stroke="black" fill="none" />
      {/* Line connecting Inverter to Load Usage */}
      <path d={`M ${inverterX + circleRadius} ${circleYPosition - 50} L ${loadX} ${circleYPosition - 50}`} stroke="black" fill="none" />
      {/* Animated Signal */}
      <circle r="3.5" fill="rgba(0,200,100, 1)">
        <animateMotion dur="15s" repeatCount="indefinite">
          <mpath href="#signalPath" />
        </animateMotion>
      </circle>

      {/* Define circles and text horizontally */}

      {/* Solar Intake */}
      <circle cx={circleRadius * 2} cy={circleYPosition - 50} r={circleRadius} fill="blue" opacity="0.45" />
      <text x={circleRadius * 2} y={circleYPosition - 55} className="SolarPF" textAnchor="middle" fill="white" dy=".3em">
        
      </text>
      <text x={circleRadius * 4 - 54} y={circleYPosition - 20} textAnchor="start" dy=".3em">
        {energyFlow ? energyFlow.pvPower : 'Loading...'} W
      </text>

      {/* Inverter  */}
      <circle cx={svgWidth / 2} cy={circleYPosition - 50} r={circleRadius} fill="orange" opacity="0.5" />
      <text x={svgWidth / 2} y={circleYPosition - 55} className="InverterPF" textAnchor="middle" fill="white" dy=".3em">
        
      </text>

      {/* Battery Percentage  */}
      <circle cx={svgWidth / 2} cy={circleYPosition + 40} r={circleRadius} fill="green" opacity="0.2" />
      <text x={svgWidth / 2} y={circleYPosition + 35} className="BatteryPF" textAnchor="middle" fill="var(--Color00cc00)" dy=".3em">
        
      </text>
      <text x={svgWidth / 2 - 25} y={circleYPosition + 70} textAnchor="start" dy=".3em">
        {energyFlow ? energyFlow.battPower * -1 : 'Loading...'} W ⋅ {energyFlow ? energyFlow.soc : 'Loading...'} %
      </text>

      {/*  Energy Load Usage  */}
      <circle cx={svgWidth - circleRadius * 2} cy={circleYPosition - 50} r={circleRadius} fill="red" opacity="0.5" />
      <text x={svgWidth - circleRadius * 2} y={circleYPosition - 55} className="LoadPF" textAnchor="middle" fill="white" dy=".3em">
        
      </text>
      <text x={svgWidth - circleRadius * 4 + 52} y={circleYPosition - 20} textAnchor="end" dy=".3em">
        {energyFlow ? energyFlow.loadOrEpsPower : 'Loading...'} W
      </text>
    </svg>
  );
};

export default PowerFlow;
