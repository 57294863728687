import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import './Community.css';

function Community({ baseURL }) {
  const [community, setCommunity] = useState(null);
  const [energySystems, setEnergySystems] = useState([]);
  const { communityId } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    //Dynamic
    const fetchCommunity = async () => {
      try {
        console.log(`Grabbing ID: ${communityId}`);
        const response = await axios.get(`${baseURL}/api/communities/${communityId}`);
        if (response.data) {
          // Make sure you're accessing the `data` property
          setCommunity(response.data);
        } else {
          console.log('Received response without data:', response);
        }
      } catch (error) {
        console.error('Error fetching community details:', error);
      }
    };

    const fetchEnergySystems = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/communities/${communityId}/plants`);
        const fetchKwHDataPromises = response.data.map(async (system) => {
          try {
            const kwhResponse = await axios.get(`${baseURL}/api/plant/${system.id}`);
            const kwhData = kwhResponse.data;

            // Safely access the nested properties with optional chaining and provide a default value if null or undefined
            const totalPower = kwhData.data?.totalPower ?? 'N/A';
            const realtime = kwhData.data?.realtime ?? 'N/A';

            return {
              ...system,
              totalPower,
              realtime,
            };
          } catch (error) {
            console.error(`Error fetching kWh data for system ${system.id}:`, error);
            // Return system with default or placeholder values to avoid breaking the application
            return {
              ...system,
              totalPower: 'N/A',
              realtime: 'N/A',
            };
          }
        });

        const systemsWithKwHData = await Promise.all(fetchKwHDataPromises);
        setEnergySystems(systemsWithKwHData);
      } catch (error) {
        console.error('Error fetching energy systems:', error);
      }
    };

    fetchCommunity();
    fetchEnergySystems(); // Fetch energy systems
  }, [communityId]);

  useEffect(() => {
    const fetchWeatherData = async (zipcode) => {
      console.log(zipcode);
      const weatherResponse = await fetch(`http://api.weatherapi.com/v1/current.json?key=ff00d3c0a2ae4fe3ac672045231912&q=${zipcode}&aqi=no`);
      const weatherData = await weatherResponse.json();

      return weatherData;
    };

    const fetchWeatherDataAndUpdateSystems = async () => {
      const updatedSystems = await Promise.all(
        energySystems.map(async (system) => {
          // Use regex to find a sequence of 5 digits towards the end of the address string
          // The regex looks for a pattern that's followed by a comma and space, and possibly other characters, until the end of the string
          const matches = system.address.match(/\b\d{5}\b(?=,.*?$)/);
          const zipcode = matches && matches.length > 0 ? matches[0] : null;
          if (!zipcode) {
            console.error(`No zipcode found for system with ID ${system.id}`);
            return system; // Return the system as is if no zipcode was found
          }

          const weatherData = await fetchWeatherData(zipcode);
          return { ...system, weather: weatherData.current }; // Append the current weather data
        })
      );

      setEnergySystems(updatedSystems); // Update state with new systems data including weather
    };

    // Once the energy systems are fetched and set, fetch and update with weather data
    if (energySystems.length > 0) {
      fetchWeatherDataAndUpdateSystems();
    }
  }, [energySystems.length]);

  const handleEnergySystemClick = (energySystemId) => {
    navigate(`/Community/${communityId}/energy-system/${energySystemId}`); // Navigate to energy system page
  };

  const formatCommissionDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  if (!community) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Community-Container">
      <div className="Community-Inner">
        <div className="Community-Titles">
          <h1>Energy Systems </h1>
          <h1 className="Community-Name">{community.name}</h1>
          {/* <h1 className="Community-SubName">{community.subname}</h1> */}
        </div>
        <div className="Community-SubInner">
          {energySystems.map((system, index) => (
            <div className="Energy-System" key={system.id}>
              <div className="Energy-System-Info">
                <div className="Energy-System-Weather">
                  {system.weather ? (
                    <>
                      <img src={`https:${system.weather.condition.icon}`} alt={system.weather.condition.text} />
                      <h1 className="degrees">{`${system.weather.temp_f}°`}</h1>
                    </>
                  ) : (
                    <span className="Sun-Cloudy"></span> // Default icon when no weather data is available
                  )}
                </div>

                <div className="Energy-System-Title">
                  <h2 className="Energy-System-Link" onClick={() => handleEnergySystemClick(system.id)}>
                    {system.name}
                  </h2>
                  <p>{system.address}</p>
                  <div className="Battery-Info BIM1">
                    <p>Battery Charge: 54 %</p>
                    <p>Battery Used: 46 %</p>
                  </div>
                  <div className="Battery-Info BIM1">
                    <p>Battery Charge: 54 %</p>
                    <p>Battery Used: 46 %</p>
                  </div>
                </div>
                <div className="Battery-Info BI1">
                  <p>kWp (Total Power): {system.realtime.totalPower}</p>
                  <p>Commission Date: {formatCommissionDate(system.createAt)}</p>
                </div>
                <div className="Battery-Info BI2">
                  <p>kWh (Today): {system.realtime.etoday} </p>
                  <p>kWh (Total): {system.etotal}</p>
                </div>
                <div className="Battery-Info BI3">
                  <p>kWh (Month): {system.realtime.emonth}</p>
                  <p>kWh (Year): {system.realtime.eyear}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="Right-Side"></div>
    </div>
  );
}

export default Community;
