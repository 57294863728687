import React, { useState, useEffect } from 'react';
import './Clients.css';
import { jwtDecode } from 'jwt-decode';
import { fetchClientsByUser, fetchClientsCommunitiesByUser, fetchCommunityPlants, fetchClientCommunitiesByClientID, getDailyEnergyPVPro, getDailyEnergyEG4, fetchGenerationUseData } from '../../services/api';

function Clients({ baseURL }) {
  const [clients, setClients] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientCommunityCount, setClientCommunityCount] = useState({});
  const [clientMap, setClientMap] = useState({});
  const [communityPlantCount, setCommunityPlantCount] = useState({});
  const [clientEnergyData, setClientEnergyData] = useState({});
  const [selectedClientId, setSelectedClientId] = useState('all');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchAndLog = async () => {
        setIsLoading(true);
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;

        try {
          const fetchedClients = await fetchClientsByUser(userId);
          const fetchedCommunities = await fetchClientsCommunitiesByUser(userId);

          console.log(`Fetched Clients: ${JSON.stringify(fetchedClients)}`);
          console.log(`Fetched Communities: ${JSON.stringify(fetchedCommunities)}`);

          setClients(fetchedClients);
          setCommunities(fetchedCommunities);

          // Map clients for easy lookup by community
          const clientMap = fetchedClients.reduce(
            (acc, client) => ({
              ...acc,
              [client.id]: client.company_name,
            }),
            {}
          );
          setClientMap(clientMap);

          // Calculate community counts per client
          const counts = await Promise.all(
            fetchedClients.map(async (client) => {
              const clientCommunities = await fetchClientCommunitiesByClientID(client.id);
              return { [client.id]: clientCommunities.length };
            })
          );

          setClientCommunityCount(counts.reduce((acc, count) => ({ ...acc, ...count }), {}));

          // Fetch plant counts and energy data for each community
          const plantCounts = {};
          const energyData = {};
          await Promise.all(
            fetchedCommunities.map(async (community) => {
              const plants = await fetchCommunityPlants(community.id);
              plantCounts[community.id] = plants.length; // Assuming the API returns an array of plants

              let totalEnergy = 0;
              let totalConsumption = 0;

              const es = plants; // Assuming `plants` is an array of energy systems

              for (const element of es) {
                let data = 0;
                let consumption = 0;

                try {
                  // PVPRO = 1, EG4 = 2
                  if (element.type === 1) {
                    const response = await getDailyEnergyPVPro(element.id);
                    data = parseInt(response.data?.realtime.etoday) || 0;
                  } else {
                    const response = await getDailyEnergyEG4(element.id, new Date().getFullYear(), new Date().getMonth() + 1, 'ePvAllDay');
                    data = parseInt(response.energy) || 0;
                  }
                  const responseConsumption = await fetchGenerationUseData(element.id);
                  consumption = parseInt(responseConsumption.consumption) || 0;
                } catch (error) {
                  console.error(`Error fetching data for element ID ${element.id}:`, error);
                }

                totalEnergy += data;
                totalConsumption += consumption;
              }

              energyData[community.id] = {
                totalEnergy,
                totalConsumption,
              };
            })
          );

          setCommunityPlantCount(plantCounts);
          setClientEnergyData(energyData);
        } catch (error) {
          console.error('Error fetching communities or clients:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchAndLog();
    }
  }, [baseURL]);

  const getClientNameById = (clientId) => {
    return clientMap[clientId] || 'Unknown Client';
  };

  const handleClientChange = (event) => {
    setSelectedClientId(event.target.value);
  };

  const filteredCommunities = selectedClientId === 'all' ? communities : communities.filter((community) => community.clientID.toString() === selectedClientId);

  return (
    <div className="Clients">
      <div className="Inner-content">
        {isLoading ? (
          <p>Loading clients...</p>
        ) : (
          clients.map((client) => {
            const clientCommunities = communities.filter((community) => community.clientID === client.id);
            const totalClientEnergy = clientCommunities.reduce((acc, community) => acc + (clientEnergyData[community.id]?.totalEnergy || 0), 0);
            const totalClientConsumption = clientCommunities.reduce((acc, community) => acc + (clientEnergyData[community.id]?.totalConsumption || 0), 0);

            return (
              <div key={client.id} className="Client-card Card">
                <div className="Client-image-container">
                  <img src={client.image || 'default-image-url.jpg'} alt={`${client.first_name} ${client.last_name}`} />
                </div>
                <div className="Client-info">
                  <h3>{client.company_name}</h3>
                  <p>
                    Name: {client.first_name} {client.last_name}
                  </p>
                  <p>Nickname: {client.nickname}</p>
                  <p>Email: {client.email}</p>
                  <p>Signed Up: {new Date(client.signedup_at).toLocaleDateString()}</p>
                  <p>Created At: {new Date(client.created_at).toLocaleDateString()}</p>
                  <p>Total Communities: {clientCommunityCount[client.id] || 0}</p>
                  <svg width="120" height="140" viewBox="0 0 120 140">
                    <circle cx="60" cy="60" r="58" stroke="black" strokeWidth="3" fill="#0be300" />
                    <circle cx="60" cy="78" r="40" stroke="black" strokeWidth="2" fill="#787878" />
                    <text x="60" y="20" textAnchor="middle" fontSize="12" fill="black">
                      {totalClientEnergy} kWh
                    </text>
                    <text x="60" y="35" textAnchor="middle" fontSize="12" fill="black">
                      Generated
                    </text>
                    <text x="60" y="60" textAnchor="middle" fontSize="12" fill="black">
                      {totalClientConsumption} kWh
                    </text>
                    <text x="60" y="75" textAnchor="middle" fontSize="12" fill="black">
                      Used
                    </text>
                  </svg>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="Communities-section">
        <h2 className="Communities-section-title">Communities</h2>
        <select onChange={handleClientChange} value={selectedClientId}>
          <option value="all">All Clients</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.company_name}
            </option>
          ))}
        </select>
        {isLoading ? (
          <p>Loading communities...</p>
        ) : (
          filteredCommunities.map((community) => (
            <div key={community.id} className="Community-card Card">
              <div className="Community-image-container">
                <img src={community.image} alt={community.name} />
              </div>
              <div className="Community-info">
                <h4>{community.name}</h4>
                <p>{community.description}</p>
                <p className="Community-client-name">Client: {getClientNameById(community.clientID)}</p>
                <p>Total Energy Systems: {communityPlantCount[community.id] || 0}</p>
                <svg width="100" height="100" viewBox="0 0 100 100">
                  <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="3" fill="#0be300" />
                  <circle cx="50" cy="50" r="30" stroke="black" strokeWidth="2" fill="#787878" />
                  <text x="50" y="45" textAnchor="middle" fontSize="10" fill="black">
                    {clientEnergyData[community.id]?.totalEnergy || 0} kWh
                  </text>
                  <text x="50" y="60" textAnchor="middle" fontSize="10" fill="black">
                    {clientEnergyData[community.id]?.totalConsumption || 0} kWh
                  </text>
                </svg>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Clients;
