import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Permissions.css';

const TreeNode = ({ label, id, checked, indeterminate, onCheck }) => {
  const handleCheck = (e) => {
    onCheck(id, e.target.checked);
  };

  return (
    <li>
      <input
        type="checkbox"
        name={id}
        id={id}
        checked={checked}
        ref={(input) => {
          if (input) {
            input.indeterminate = indeterminate;
          }
        }}
        onChange={handleCheck}
      />
      <label htmlFor={id} className={indeterminate ? 'custom-indeterminate' : checked ? 'custom-checked' : 'custom-unchecked'}>
        {label}
      </label>
    </li>
  );
};

// Helper function to group permissions by category
const groupPermissionsByCategory = (permissions) => {
  return permissions.reduce((acc, perm) => {
    if (!acc[perm.category]) {
      acc[perm.category] = [];
    }
    acc[perm.category].push(perm);
    return acc;
  }, {});
};

const Permissions = ({ baseURL }) => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [allPermissions, setAllPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const rolesResponse = await axios.get(`${baseURL}/api/users/usertypes`);
      setRoles(rolesResponse.data.map((role) => ({ label: role.typeName, value: role.id })));

      const permissionsResponse = await axios.get(`${baseURL}/api/users/permissions`);
      const permissionsData = permissionsResponse.data.map((perm, index) => ({
        id: `perm-${index}`,
        label: perm.permission_name,
        checked: false,
        indeterminate: false,
        category: perm.Category, // Make sure you have a category field in your data
      }));

      const groupedPermissions = groupPermissionsByCategory(permissionsData);
      setAllPermissions(groupedPermissions);
    };
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchPermissionsForRole = async () => {
      const response = await axios.get(`${baseURL}/api/users/permissions/${selectedRole}`);
      setRolePermissions(response.data);
      updatePermissionsCheckState(response.data);
    };

    if (selectedRole) {
      fetchPermissionsForRole();
    }
  }, [selectedRole]);

  const updatePermissionsCheckState = (rolePerms) => {
    setAllPermissions(
      Object.keys(allPermissions).reduce((acc, category) => {
        acc[category] = allPermissions[category].map((perm) => ({
          ...perm,
          checked: rolePerms.includes(perm.label),
        }));
        return acc;
      }, {})
    );
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setAllPermissions(
      Object.keys(allPermissions).reduce((acc, category) => {
        acc[category] = allPermissions[category].map((perm) => ({
          ...perm,
          checked: false,
        }));
        return acc;
      }, {})
    );
  };

  const handleCheck = (id, isChecked) => {
    setAllPermissions(
      Object.keys(allPermissions).reduce((acc, category) => {
        acc[category] = allPermissions[category].map((perm) => {
          if (perm.id === id) {
            return { ...perm, checked: isChecked };
          }
          return perm;
        });
        return acc;
      }, {})
    );
  };

  const handleUpdatePermissions = async () => {
    if (!selectedRole) {
      alert('Please select a role to update permissions.');
      return;
    }

    try {
      const updatedPermissions = Object.keys(allPermissions).flatMap((category) => allPermissions[category].filter((perm) => perm.checked).map((perm) => perm.label));

      await axios.put(`${baseURL}/api/users/permissions/${selectedRole}`, {
        permissions: updatedPermissions,
      });
      alert('Permissions updated successfully.');
    } catch (error) {
      console.error('Error updating permissions:', error);
      alert('Failed to update permissions.');
    }
  };

  return (
    <div id="page-wrap">
      <div className="permissions-header">
        <h2>Permissions</h2>
        <div className="role-select-container">
          <select value={selectedRole} onChange={handleRoleChange} className="role-dropdown">
            <option value="">Select a role...</option>
            {roles.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
          </select>
          <button onClick={handleUpdatePermissions} className="update-permissions-btn" disabled={!selectedRole}>
            Update Permissions
          </button>
        </div>
      </div>
      <ul className="treeview">
        {Object.keys(allPermissions).map((category) => (
          <li key={category}>
            <strong>{category}</strong>
            <ul>
              {allPermissions[category].map((perm) => (
                <TreeNode key={perm.id} label={perm.label} id={perm.id} checked={perm.checked} indeterminate={perm.indeterminate} onCheck={handleCheck} />
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Permissions;
