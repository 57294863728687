import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement
import './EnergySystems.css';
// Import your custom components
import Community from '../../components/CommunityCard/CommunityCard';
import EnergySystemMainCard from '../../components/EnergySystemMainCard/EnergySystemMainCard';
import EnergySystemMap from '../../components/EnergySystemMap/EnergySystemMap'; // Import the map component

function EnergySystems({ baseURL }) {
  const [energySystems, setEnergySystems] = useState([]);
  const [activeTab, setActiveTab] = useState('allSystems');
  const [isLoading, setIsLoading] = useState(true); // To control the loading state

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchAndGeocodeEnergySystems = async () => {
        setIsLoading(true);
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;

        try {
          const { data: communities } = await axios.get(`${baseURL}/api/communities/user/${userId}`);

          let systems = [];
          for (const community of communities) {
            const { data: communitySystems } = await axios.get(`${baseURL}/api/communities/${community.id}/plants`);
            systems = systems.concat(communitySystems.map((system) => ({ ...system, community: community.name })));
          }

          // Geocode each system's location
          const geocodedSystems = await Promise.all(
            systems.map(async (system) => {
              try {
                const geocodeResponse = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(system.address)}&key=AIzaSyA7oK-vK9ssx6bXu9Yv_5ZnJV0r0i2YDdo`);
                if (geocodeResponse.data.status === 'OK') {
                  const { lat, lng } = geocodeResponse.data.results[0].geometry.location;
                  return { ...system, latitude: Number(lat), longitude: Number(lng) };
                }
              } catch (error) {
                console.error('Geocoding failed:', error);
              }
              return system; // Return system as is if geocoding fails
            })
          );

          setEnergySystems(geocodedSystems);
        } catch (error) {
          console.error('Error fetching communities or energy systems:', error);
        } finally {
          setIsLoading(false); // Ensure loading state is updated regardless of success or failure
        }
      };

      fetchAndGeocodeEnergySystems();
    }
  }, [baseURL]); // This effect should only run once on component mount, hence no dependency on energySystems

  return (
    <div className="Communities">
      <div className="Inner-content">
        <div className="tabs">
          <button onClick={() => setActiveTab('allSystems')} className={activeTab === 'allSystems' ? 'active' : ''}>
            Energy Systems
          </button>
          <button onClick={() => setActiveTab('Map')} className={activeTab === 'Map' ? 'active' : ''}>
            Map
          </button>
        </div>

        {activeTab === 'allSystems' && (
          <div className="energy-systems-main-container">
            <div className="energy-systems-main-section">
              {energySystems.map((system, index) => (
                <EnergySystemMainCard key={index} system={system} />
              ))}
            </div>
          </div>
        )}
        {activeTab === 'Map' && (
          <div className="Map-section">
            <EnergySystemMap energySystems={energySystems} />
          </div>
        )}
      </div>
    </div>
  );
}

export default EnergySystems;
