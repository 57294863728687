import axios from 'axios';

const baseURL = 'http://dash.clearvision.gg:3001'; // Define your base URL here

// Function to get the bearer token from local storage
const getToken = () => {
  return localStorage.getItem('token');
};

// Common headers for all requests
const getHeaders = () => {
  const token = getToken();
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const fetchCommunities = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/communities`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error fetching communities:', error);
  }
};

export const fetchUsers = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/users`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return []; // Return an empty array in case of error to prevent app crash
  }
};

export const fetchEnergySystems = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/plants/list`, { headers: getHeaders() });
    return response.data; // Return the list of energy systems
  } catch (error) {
    console.error('Error fetching energy systems:', error);
    throw error; // Re-throw the error to be handled by the caller
  }
};

export const fetchCommunityUsers = async (communityId) => {
  try {
    const response = await axios.get(`${baseURL}/api/communities/${communityId}/users`, { headers: getHeaders() });
    return response.data.users; // Return the users data
  } catch (error) {
    console.error('Error fetching users for community:', error);
    throw error; // Rethrow to handle it in the calling function
  }
};

export const fetchCommunitiesByUser = async (userId) => {
  try {
    const response = await axios.get(`${baseURL}/api/communities/user/${userId}`, { headers: getHeaders() });
    return response.data; // Return the users data
  } catch (error) {
    console.error('Error fetching users for community:', error);
    throw error; // Rethrow to handle it in the calling function
  }
};

export const fetchClientsByUser = async (userId) => {
  try {
    const response = await axios.get(`${baseURL}/api/clients/byuser/${userId}`, { headers: getHeaders() });
    return response.data; // Return the users data
  } catch (error) {
    console.error('Error fetching users for community:', error);
    throw error; // Rethrow to handle it in the calling function
  }
};

export const fetchClientCommunitiesByClientID = async (clientId) => {
  try {
    const response = await axios.get(`${baseURL}/api/clients/communities/${clientId}`, { headers: getHeaders() });
    return response.data; // Return the users data
  } catch (error) {
    console.error('Error fetching users for community:', error);
    throw error; // Rethrow to handle it in the calling function
  }
};

export const fetchClientsCommunitiesByUser = async (userId) => {
  try {
    const response = await axios.get(`${baseURL}/api/clients/communities/byuser/${userId}`, { headers: getHeaders() });
    return response.data; // Return the users data
  } catch (error) {
    console.error('Error fetching users for community:', error);
    throw error; // Rethrow to handle it in the calling function
  }
};

export const fetchCommunityPlants = async (communityId) => {
  try {
    const response = await axios.get(`${baseURL}/api/communities/${communityId}/plants`, { headers: getHeaders() });
    return response.data; // Return the plants data
  } catch (error) {
    console.error(`Error fetching plants for community ${communityId}:`, error);
    throw error; // Rethrow to handle it in the calling function
  }
};

// Assign a user to a community
export const assignUserToCommunity = async (communityId, userId) => {
  try {
    const response = await axios.post(`${baseURL}/api/communities/${communityId}/users/${userId}`, null, { headers: getHeaders() });
    return response; // Assuming successful response structure
  } catch (error) {
    console.error('Error assigning user to community:', error);
    throw error;
  }
};

// Assign an energy system to a community
export const assignEnergySystemToCommunity = async (communityId, plantId) => {
  try {
    const response = await axios.post(`${baseURL}/api/communities/${communityId}/plants/${plantId}`, null, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error assigning energy system to community:', error);
    throw error;
  }
};

// Fetch the history of a specific energy system
export const fetchEnergySystemHistory = async (energySystemId) => {
  try {
    const response = await axios.get(`${baseURL}/api/pv/plants/energy-system-history/${energySystemId}`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Handle 404 response gracefully, e.g., return an empty array
      console.warn('Energy system history not found:', error.response.data);
      return [];
    } else {
      console.error('Error fetching energy system history:', error);
      throw error;
    }
  }
};

// Delete a specific entry from an energy system's history
export const deleteEnergySystemHistory = async (historyId) => {
  try {
    await axios.delete(`${baseURL}/api/energy-system-history/${historyId}`, { headers: getHeaders() });
  } catch (error) {
    console.error('Error deleting energy system history:', error);
    throw error;
  }
};

// Remove a user from a community
export const removeUserFromCommunity = async (communityId, userId) => {
  try {
    await axios.delete(`${baseURL}/api/communities/${communityId}/users/${userId}`, { headers: getHeaders() });
  } catch (error) {
    console.error(`Error removing user ${userId} from community ${communityId}:`, error);
    throw error;
  }
};

// Remove a plant from a community
export const removePlantFromCommunity = async (communityId, plantId) => {
  try {
    await axios.delete(`${baseURL}/api/communities/${communityId}/plants/${plantId}`, { headers: getHeaders() });
  } catch (error) {
    console.error(`Error removing plant ${plantId} from community ${communityId}:`, error);
    throw error;
  }
};

export const updateCommunity = async (communityId, formData) => {
  console.log(`communityid ${communityId}`);
  const endpoint = communityId ? `/api/communities/${communityId}` : '/api/communities';
  const method = communityId ? 'put' : 'post';
  try {
    const response = await axios[method](`${baseURL}${endpoint}`, formData, { headers: getHeaders() });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error updating community:', error);
    throw error; // Rethrow to handle it in the UI layer
  }
};

// Update an energy system's details
export const updateEnergySystem = async (energySystemId, formData) => {
  try {
    const response = await axios.put(`${baseURL}/api/communities/plants/${energySystemId}`, formData, { headers: getHeaders() });
    console.log(response);
    return response.data; // Assuming the API returns the updated energy system
  } catch (error) {
    console.error('Error updating energy system:', error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Fetch weather data
export const fetchWeatherData = async (zipcode) => {
  try {
    const response = await axios.get(`http://api.weatherapi.com/v1/current.json`, {
      params: {
        key: 'ff00d3c0a2ae4fe3ac672045231912',
        q: zipcode,
        aqi: 'no',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching weather data:', error);
    // Handle error appropriately
  }
};

// Fetch Energy System Generation Use
export const fetchGenerationUseData = async (plantId) => {
  try {
    // First, get the plant system details to determine the type
    const plantDetails = await fetchPlantSystemDetails(plantId);
    const plantType = plantDetails.type; // Assuming plantDetails contains a 'type' property

    let response;
    if (plantType === 1) {
      // For PVPro (type 1), use params
      response = await axios.get(`${baseURL}/api/pv/plants/generation-use`, {
        params: { energySystemId: plantId },
        headers: getHeaders(),
      });
    } else if (plantType === 2) {
      // For EG4 (type 2), use query parameters in the URL directly
      response = await axios.get(`${baseURL}/api/eg4/plants/generation-use`, {
        params: { id: plantId },
        headers: getHeaders(),
      });
    } else {
      throw new Error('Unknown plant type');
    }

    // Normalize response data for both types
    const responseData = plantType === 1 ? response.data.data : response.data;

    const { load, pv, batteryCharge } = responseData;
    return {
      consumption: load,
      generationUseData: {
        labels: ['Consumption', 'Charging', 'E-Today'],
        datasets: [
          {
            label: 'Energy Distribution',
            data: [load, batteryCharge, pv],
            backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
            hoverOffset: 4,
          },
        ],
        text: `${pv} kWh E-Today`,
      },
    };
  } catch (error) {
    console.error(`Error fetching generation-use data for plantId ${plantId}:`, error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Fetch Energy System Details
export const fetchEnergySystemDetails = async (energySystemId) => {
  try {
    const response = await axios.get(`${baseURL}/api/pv/plants/energy-system/${energySystemId}`);
    let energysysDetails = response.data;

    //console.log(energysysDetails);

    // Assuming energysysDetails contains an 'address' field
    const address = energysysDetails.address;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyA7oK-vK9ssx6bXu9Yv_5ZnJV0r0i2YDdo`;

    const geocodeResponse = await axios.get(geocodeUrl);
    if (geocodeResponse.data.status === 'OK') {
      const location = geocodeResponse.data.results[0].geometry.location;
      energysysDetails = {
        ...energysysDetails,
        latitude: location.lat,
        longitude: location.lng,
      };
    } else {
      console.error('Geocoding failed:', geocodeResponse.data.status);
      // Optionally handle the error case where geocoding does not return an OK status
    }

    return energysysDetails;
  } catch (error) {
    console.error('Error fetching energy system details:', error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Fetch Energy System Realtime Data
export const fetchRealtimeData = async (plantId) => {
  try {
    // First, get the plant system details to determine the type
    const plantDetails = await fetchPlantSystemDetails(plantId);
    const plantType = plantDetails.type; // Assuming plantDetails contains a 'type' property

    let response;
    if (plantType === 1) {
      // For PVPro (type 1), use params
      response = await axios.get(`${baseURL}/api/pv/plants/realtime-data`, {
        params: { energySystemId: plantId },
        headers: getHeaders(),
      });
    } else if (plantType === 2) {
      // For EG4 (type 2), append plantId directly to the URL
      response = await axios.get(`${baseURL}/api/eg4/plants/realtime-data?id=${plantId}`, {
        headers: getHeaders(),
      });
    } else {
      throw new Error('Unknown plant type');
    }

    // Process the response based on plant type
    const data = plantType === 1 ? response.data.data : response.data; // Adjust based on response structure

    // Adjusting data to match a unified structure if necessary
    // For instance, converting 'updateAt' format from EG4 to match PVPro's ISO format
    if (plantType === 2) {
      data.updateAt = new Date(data.updateAt).toISOString();
    }

    return data; // Return the adjusted real-time data
  } catch (error) {
    console.error(`Error fetching realtime data for plantId ${plantId}:`, error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Fetch Community Details
export const fetchCommunityDetails = async (communityId) => {
  try {
    const response = await axios.get(`${baseURL}/api/communities/${communityId}`);
    //console.log('Community Details:', response.data); // Logging here is optional
    return response.data; // Return the community details for the caller to use
  } catch (error) {
    console.error('Error fetching community details:', error);
    throw error; // Throw the error for the caller to handle
  }
};

// Fetch Energy System Flow Data
export const fetchEnergyFlowData = async (plantId) => {
  try {
    // First, get the plant system details to determine the type
    const plantDetails = await fetchPlantSystemDetails(plantId);
    const plantType = plantDetails.type; // Assuming plantDetails contains a 'type' property

    let responseData;
    // Check the plant type to decide the API endpoint and request method
    if (plantType === 1) {
      // For PVPro (type 1), use params as before
      const response = await axios.get(`${baseURL}/api/pv/plants/energy-flow`, {
        params: { energySystemId: plantId },
        headers: getHeaders(),
      });
      responseData = response.data.data; // Use .data.data for type 1
    } else if (plantType === 2) {
      // For EG4 (type 2), adjust to use query parameters in the URL
      const response = await axios.get(`${baseURL}/api/eg4/plants/energy-flow?id=${plantId}`, {
        headers: getHeaders(),
      });
      responseData = response.data; // Use .data for type 2 as the structure is directly returned
    } else {
      throw new Error('Unknown plant type');
    }

    //console.log('Energy Flow Data:', responseData); // Logging here to see the final structured used
    return responseData; // Return the processed energy flow data
  } catch (error) {
    console.error(`Error fetching energy flow data for plantId ${plantId}:`, error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Fetch Plant System Details
export const fetchPlantSystemDetails = async (plantId) => {
  try {
    // Append plantId directly to the URL
    const response = await axios.get(`${baseURL}/api/eg4/plants/system?id=${plantId}`, {
      headers: getHeaders(),
    });
    //console.log('EG4 Plant System Details:', response.data);
    return response.data; // Assuming the response structure fits your needs
  } catch (error) {
    console.error(`Error fetching EG4 plant system details for plantId ${plantId}:`, error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Fetch Plant History Data
export const fetchPlantHistory = async (plantId) => {
  try {
    // Append plantId directly to the URL
    const response = await axios.get(`${baseURL}/api/reports/ESHistory/${plantId}`, {
      headers: getHeaders(),
    });
    return response.data; // Assuming the response structure fits your needs
  } catch (error) {
    console.error(`Error fetching EG4 plant system details for plantId ${plantId}:`, error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Fetch Plant History Events
export const fetchPlantHistoryEvents = async () => {
  try {
    // Get the Event List --   return ['Delivery', 'Pick Up', 'Created', 'Available', 'Deconstructed', 'Out of Service', 'Maintenance'];
    // Then it will return a json from the route
    const response = await axios.get(`${baseURL}/api/reports/ESHistory/Events`, {
      headers: getHeaders(),
    });
    return response.data; // Assuming the response structure fits your needs
  } catch (error) {
    console.error(`Error fetching Plant History Events`, error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Update Plant History
export const updatePlantHistory = async (refid, eventData) => {
  try {
    const { Event, EventDate, CommunityId, UserId } = eventData;
    if (!Event || !EventDate || !CommunityId || !UserId) {
      throw new Error('Missing required fields to update plant history');
    }

    const response = await axios.post(
      `${baseURL}/api/reports/ESHistory/${refid}/update`,
      {
        Event,
        EventDate,
        CommunityId,
        UserId,
      },
      {
        headers: getHeaders(),
      }
    );

    return response.data; // Assuming the response structure fits your needs
  } catch (error) {
    console.error(`Error updating history for plantId ${refid}:`, error);
    throw error; // Rethrowing the error for the caller to handle
  }
};

// Create Plant History
export const createPlantHistory = async (plantId, historyData) => {
  try {
    const { Event, EventDate, CommunityId, UserId } = historyData;
    if (!Event || !EventDate || !CommunityId || !UserId) {
      throw new Error('Missing required fields to create plant history');
    }

    // Prepare the payload with additional necessary details
    const payload = {
      Event,
      EventDate,
      CommunityId,
      UserId,
      energySystemId: plantId, // Adding the plantId as energySystemId
    };

    const response = await axios.post(
      `${baseURL}/api/reports/ESHistory/create`, // Adjust if the endpoint differs
      payload,
      {
        headers: getHeaders(),
      }
    );

    return response.data; // Assuming the response structure fits your needs
  } catch (error) {
    console.error(`Error creating history for plantId ${plantId}:`, error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Function to create or update a community
export const saveCommunity = async (communityData, communityId = null) => {
  try {
    // Check if it's an update or create operation based on communityId
    const endpoint = communityId ? `${baseURL}/api/communities/${communityId}` : `${baseURL}/api/communities/`;
    const method = communityId ? 'put' : 'post';

    const response = await axios({
      method: method,
      url: endpoint,
      data: communityData,
      headers: getHeaders(),
    });

    return response.data; // Assuming the response structure fits your needs
  } catch (error) {
    console.error(`Error ${communityId ? 'updating' : 'creating'} community:`, error);
    throw error; // It's a good practice to throw the error so the caller can handle it
  }
};

// Get daily energy usage for energy system - eg4
export const getDailyEnergyEG4 = async (serialNum, year, month, energyType) => {
  try {
    const response = await axios.get(`${baseURL}/api/eg4/plants/month/daily`, {
      params: {
        serialNum,
        year,
        month,
        energyType,
      },
      headers: getHeaders(),
    });

    const today = new Date().getDate();
    const dailyData = response.data.energyData.find((dayData) => dayData.day === today);

    return dailyData;
  } catch (error) {
    console.error(`Error fetching daily energy usage for serialNum ${serialNum}:`, error);
    throw error;
  }
};

// Get daily energy usage for energy system - pvpro
export const getDailyEnergyPVPro = async (energySystemId) => {
  try {
    const response = await axios.get(`${baseURL}/api/pv/plants/Plant-Info`, {
      params: {
        energySystemId,
      },
      headers: getHeaders(),
    });

    return response.data;
  } catch (error) {
    console.error(`Error fetching daily energy usage for energySystemId ${energySystemId}:`, error);
    throw error;
  }
};

export const fetchEnergySystemsForCommunity = async (community) => {
  try {
    const response = await axios.get(`${baseURL}/api/communities/${community.id}/plants`);
    // Append the community name to each energy system
    const energySystemsWithCommunity = response.data.map((system) => ({
      ...system,
      community: community.name,
      communityid: community.id,
    }));
    return energySystemsWithCommunity;
  } catch (error) {
    console.error(`Error fetching energy systems for community ${community.id}:`, error);
    return []; // Return an empty array in case of an error
  }
};
