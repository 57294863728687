import React from 'react';
import Select from 'react-select';

function EnergySystemAssignment({ communities, energySystems, currentCommunity, selectedEnergySystem, handleCommunitySelect, setSelectedEnergySystem, assignEnergySystem }) {
  return (
    <div className="assign-container">
      <h3 className="Energy-Systems-Title">Energy Systems</h3>
      <div className="select-input">
        <Select placeholder="Select Community" options={communities.map((community) => ({ label: community.name, value: community.id }))} onChange={handleCommunitySelect} value={currentCommunity && { label: currentCommunity.name, value: currentCommunity.id }} />
      </div>
      <div className="select-input">
        <Select placeholder="Select Energy System" options={energySystems.map((system) => ({ label: system.name, value: system.id }))} onChange={setSelectedEnergySystem} value={selectedEnergySystem} />
      </div>
      <button className="assign-button" onClick={assignEnergySystem} disabled={!currentCommunity || !selectedEnergySystem}>
        Assign Energy System
      </button>
    </div>
  );
}

export default EnergySystemAssignment;
