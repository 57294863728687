import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // For example, using react-select for the dropdown
import './UserManager.css';
import axios from 'axios';
import Permissions from '../../components/Permissions/Permissions';

function UserManager({ baseURL }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    userType: '',
  });

  useEffect(() => {
    fetchUsers();
    fetchUserTypes();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchUserTypes = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/users/usertypes`);
      // Ensure the response is an array before setting the state
      if (Array.isArray(response.data)) {
        setUserTypes(response.data);
      } else {
        console.log(response.data);
        console.error('Received non-array response data for user types:', response.data);
        setUserTypes([]); // Set to empty array if the response is not an array
      }
    } catch (error) {
      console.error('Error fetching user types:', error);
      setUserTypes([]); // Set to empty array in case of error
    }
  };

  const handleEdit = (user) => {
    setIsEditing(true);
    setSelectedUser(user);
    console.log(`Select user` + JSON.stringify(user));
    const userTypeObject = userTypes.find((type) => type.id === user.userType);
    setFormData({
      username: user.username,
      // Don't set password for security reasons
      email: user.email,
      phone: user.phone,
      userType: userTypeObject ? { label: userTypeObject.typeName, value: userTypeObject.id } : '',
    });
  };

  // npm install @mui/material @emotion/react @emotion/styled
  // npm install @mui/icons-material
  //  npm install @fontsource/roboto

  const handleCreateOrUpdateUser = async (e) => {
    e.preventDefault();

    try {
      let response;
      if (isEditing) {
        response = await axios.put(`${baseURL}/api/users/${selectedUser.id}`, formData);

        let userData = formData;
        userData.userType = formData.userType.value;

        setUsers(users.map((user) => (user.id === selectedUser.id ? { ...user, ...userData } : user)));
        alert('User updated successfully'); // Replace with a more sophisticated method
      } else {
        response = await axios.post(`${baseURL}/api/users/create`, formData);
        setUsers([...users, response.data]); // Assuming the response includes the new user
        alert('User created successfully'); // Replace with a more sophisticated method
      }
      // Reset form and state
      setFormData({
        username: '',
        email: '',
        phone: '',
        userType: '',
      });
      setIsEditing(false);
      setSelectedUser(null);
    } catch (error) {
      console.error('Error:', error.response);
      alert('An error occurred'); // Replace with a more sophisticated error handling
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this user?');
    if (confirmDelete) {
      try {
        await axios.delete(`${baseURL}/api/users/${userId}`);
        // Update the state to remove the deleted user
        setUsers(users.filter((user) => user.id !== userId));
        alert('User deleted successfully');
      } catch (error) {
        console.error('Error deleting user:', error.response);
        alert('An error occurred while deleting the user');
      }
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUserTypeChange = (selectedOption) => {
    /*
    Implement Logic to use selectedOption (example: Administrator) and use the id instead (1) 
    */
    console.log(selectedOption);

    setFormData({ ...formData, userType: selectedOption });
  };

  const formatUsername = (username) => {
    if (!username) return '';
    return username.charAt(0).toUpperCase() + username.slice(1).toLowerCase();
  };

  return (
    <div className="container">
      <div className="user-manager">
        <div className="user-manager-row">
          <div className="user-list-container">
            <h3>USER LIST</h3>
            <ul>
              {users.map((user) => {
                const userType = userTypes.find((type) => type.id === user.userType);
                const roleName = userType ? userType.typeName : 'No role assigned';
                const formattedUsername = formatUsername(user.username);

                return (
                  <li key={user.id}>
                    <div className="user-details">
                      <div className="user-name">{formattedUsername}</div> {/* Formatted username */}
                      <div className="user-role">{roleName}</div>
                    </div>
                    <div className="button-group">
                      <button onClick={() => handleEdit(user)}>
                        <i className="fas fa-pencil-alt"></i>
                      </button>
                      <button onClick={() => handleDeleteUser(user.id)}>
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="user-form-container">
            <h3>Create User</h3>
            <form onSubmit={handleCreateOrUpdateUser}>
              <div className="user-form-group">
                <label>
                  Username:
                  <input type="text" name="username" value={formData.username} onChange={handleInputChange} placeholder="Username" />
                </label>
              </div>
              <div className="user-form-group">
                <label>
                  Email:
                  <input type="text" name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" />
                </label>
              </div>
              <div className="user-form-group">
                <label>
                  Phone Number:
                  <input type="text" name="phone" value={formData.phone} onChange={handleInputChange} placeholder="Phone" />
                </label>
              </div>
              <div className="select-input">
                <label>
                  User Type:
                  <Select classNamePrefix="react-select" placeholder="Select User Type" options={userTypes.map((type) => ({ label: type.typeName, value: type.id }))} onChange={handleUserTypeChange} value={formData.userType} />{' '}
                </label>
              </div>
              <button type="submit" className="user-form-container-button">
                {isEditing ? 'Update User' : 'Create User'}
              </button>
            </form>
          </div>
        </div>
        <div className="user-manager-row">
          <div className="permissions-container">
            <Permissions baseURL={baseURL} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManager;
