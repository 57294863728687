import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import JSMpeg from '@cycjimmy/jsmpeg-player';

const VideoPlayer = ({ wsUrl, channel, streamUrl, baseURL }) => {
  const canvasRef = useRef(null);
  const [play, setPlay] = useState(false);

  const getToken = () => localStorage.getItem('token'); // Adjust if using sessionStorage

  const startStream = async () => {
    const Token = getToken(); // Retrieve the token from local storage
    if (!Token) {
      console.error('No token found');
      return; // Optionally handle this case (e.g., redirect to login)
    }

    try {
      await axios.post(`${baseURL}/api/pv/plants/energy-system/start-stream`, { url: streamUrl, channel }, { headers: { Authorization: `Bearer ${Token}` } });
      setPlay(true); // Set play to true only after the stream has successfully started
    } catch (error) {
      console.error('Error starting stream:', error);
    }
  };

  const stopStream = async () => {
    const Token = getToken(); // Retrieve the token from local storage
    if (!Token) {
      console.error('No token found');
      return; // Optionally handle this case
    }

    if (play) {
      try {
        await axios.post(`${baseURL}/api/pv/plants/energy-system/stop-stream`, { channel }, { headers: { Authorization: `Bearer ${Token}` } });
        setPlay(false); // Set play to false immediately upon stopping the stream
      } catch (error) {
        console.error('Error stopping stream:', error);
      }
    }
  };

  useEffect(() => {
    let player = null;
    let timeoutId = null;

    if (play && canvasRef.current) {
      player = new JSMpeg.Player(wsUrl, { canvas: canvasRef.current });
      timeoutId = setTimeout(() => {
        stopStream();
      }, 35000);
    }

    return () => {
      if (player) {
        player.destroy();
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [wsUrl, play, channel, streamUrl]);

  const handlePlayClick = () => {
    if (!play) {
      startStream();
    }
  };

  const handleStopClick = () => {
    stopStream();
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
      {!play ? (
        <div onClick={handlePlayClick} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#000', height: '100%', width: '100%' }}>
          <div style={{ color: '#fff', fontSize: '20px' }}>Click to Play Camera {channel}</div>
        </div>
      ) : (
        <>
          <canvas ref={canvasRef} className="videoPlayerCanvas" style={{ width: '100%', height: 'auto' }} />
          <button onClick={handleStopClick} className="stopStreamButton">
            Stop Stream
          </button>
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
